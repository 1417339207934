import axios from "axios";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { NotificationManager } from 'react-notifications';


axios.defaults.withCredentials = true;
const MySwal = withReactContent(Swal);


export const AUTH_URL = process.env.REACT_APP_SERVER_URL;
export const SERVER_URL = AUTH_URL + '/api/';
export const FRONTEND_URL = process.env.REACT_APP_FRONTEND_URL;
export const ADMINEND_URL = process.env.REACT_APP_ADMINEND_URL;
export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
export const APP_VERSION = process.env.REACT_APP_VERSION;
export const APP_LAST_UPDATED = new Date(Number(process.env.REACT_APP_LAST_UPDATED) * 1000);



var style = 'color: tomato; background:#eee; -webkit-text-stroke: 0.5px black; font-size:14px; padding:5px 15px;';
var style2 = 'color: #fff; background:#000; -webkit-text-stroke: 0.1px #f2f2f2; font-size:10px; padding:5px 15px;';

console.log('%cHey Devo!, Happy Debugging 😆', style );
console.log('%cAUTH URL: ' + AUTH_URL + '\nSERVER URL: ' + SERVER_URL + '\nFRONTEND URL: ' + FRONTEND_URL + '\nADMINEND URL: ' + ADMINEND_URL + '\nENVIRONMENT: ' + ENVIRONMENT + '\nAPP VERSION: ' + APP_VERSION + '\nAPP LAST UPDATED: ' + APP_LAST_UPDATED, style2 );


export var bearerToken = '';
export var bearerTokenExpiration = '';
export var tepngUserInfo = {};
export const Spinner = '<i class="fa fa-spin fa-circle-o-notch"></i> Loading..'
var timerRefno


/**
 * @param {number} price
 * @return {string}
 */
export function formatAmount(price) {
  if (price)
    return price.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  else
    return '0'
}


/**
 * @param {number} value
 * @return {string}
 */
export function twoDecimalPlace(value) {
  if (value)
    return value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  else
    return '0';
}


/**
 * @param {String} date
 * @param {String} format
 * @return {string}
 */
export function formatDate(date, format) {
  if (!date)
    return "";

  let d = new Date(date);

  if (format === 'time') {
    return d.toLocaleTimeString('en-US');
  }
  else if (format === 'date') {
    return d.toDateString();
  }
  else {
    return d.toDateString() + ' ' + d.toLocaleTimeString('en-US');
  }
}


/**
 * @param {String} message
 */
export function errorAlert(message) {
  MySwal.fire({
    icon: 'error',
    title: 'Oops...',
    timer: 80000,
    timerProgressBar: true,
    html: `<small>${message}</small>`,
    footer: '<a href="/issues" style="font-size:12px">Why do I have this issue?</a>',
    showDenyButton: true,
    denyButtonText: `<small>Ok, thanks!</small>`,
    showConfirmButton: false,
  })
}


/**
 * @param {String} message
 */
export function successAlert(message) {
  MySwal.fire({
    icon: 'success',
    title: 'Success Alert',
    html: `<small>${message}</small>`
  })
}


/**
 */
export function Preloader() {
  MySwal.fire({ title: "", html: '<div style="height:150px;width:150px;margin: 0 auto;"><img style="width: 100px;" src="../loading-icon-red.gif" /><br> <strong> Please Wait... </strong> </div>', showConfirmButton: false, showCancelButton: false, allowOutsideClick: false, }
  )
}

/**
 *
 */
export function closeAlert() {
  MySwal.close();
}


/**
 * @param {String} message
 */
export function confirmPrompt(message) {
  return MySwal.fire({
    // title: 'Alert',
    html: `<small style="font-size:14px">${message}</small>`,
    icon: 'info',
    showCancelButton: true,
    confirmButtonColor: '#3085d6',
    cancelButtonColor: 'red',
    confirmButtonText: 'Yes',
    cancelButtonText: 'No'
  })
}

export function alertPrompt(message) {
  return MySwal.fire({
    title: 'Alert',
    html: `<small>${message}</small>`,
    icon: 'info',
    showCancelButton: false,
    confirmButtonColor: '#3085d6',
    confirmButtonText: 'Ok'
  })
}

/**
 * @param {object} error
 */
export function errorAction(error) {
  if (error?.response?.status) {
    if (error.response.status === 401) {
      // alert("Error Detected! Your page will reload!");
      // window.location.reload();
      console.log('Refresh...');
      errorAlert(error.message);
    }
    else {
      if (error.response.data.message === undefined)
        if (error.response.data.size > 1)
          errorAlert('No documents have been uploaded.');
        else
          errorAlert(error.message);
      else
        errorAlert(error.response.data.message);
    }
  }
  else {
    errorAlert(error.message);
  }
}


/**
 * @param {boolean} requiresToken
 * @return {boolean}
 */
export function isAccessTokenValid(requiresToken) {
  if (requiresToken !== true)
    return true;

  else {
    if (bearerTokenExpiration === '')
      return false;

    let expiration = new Date(bearerTokenExpiration);
    let current = new Date();

    let time_extras = 3 * 60 * 1000;
    if ((expiration.getTime() - time_extras) > current.getTime())
      return true;

    else
      return false;
  }
}


/**
 * @param {string} password
 * @return {boolean}
 */
export function passwordRules(password) {
  if (!password.match(/^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])[a-zA-Z0-9!@#$%^&*]{8,}$/)) {
    return true;
  }
}


/**
 */
export function logout() {
  Preloader();
  return axios({
    method: "post",
    url: SERVER_URL + "AuthenticateTepngUser/Logout",
    withCredentials: true,
    data: {},
    headers: { "Content-Type": "application/json", "Authorization": `Bearer ${bearerToken}` }
  })
    .then(res => {
      bearerToken = null;
      bearerTokenExpiration = null;
      tepngUserInfo = null;
      localStorage.clear();
      window.location.replace('/login-tepnguser');
    })
}

// export function logout() {
//   let url = new URL(window.location.origin + '/login-tepnguser')
//   url.searchParams.append('azureauthredirect', '1')
//   window.location.replace(AUTH_URL + '/.auth/logout/aad?post_logout_redirect_uri=' + window.encodeURIComponent(url.href));
//   //https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=https%3a%2f%2fmanage.windowsazure.com%2fSignOut%2fComplete
// }


/**
 */
export async function refreshToken(redirectToLogin) {
  await axios({
    method: "post",
    url: SERVER_URL + "AuthenticateTepngUser/RefreshToken",
    withCredentials: true,
    data: {},
    headers: { "Content-Type": "application/json" }
  })
    .then(res => {
      let data = res.data.data.tepngUserAccessToken;
      bearerToken = data.token;
      bearerTokenExpiration = data.expiration;
      tepngUserInfo = res.data.data.tepngUser;
    })
    .catch(error => {
      bearerToken = null;
      bearerTokenExpiration = null;
      tepngUserInfo = null;
      if (redirectToLogin) {
        alert("Session Expired, you will be redirected to login page");
        window.location.href = '/login-tepnguser'
      }
    })

  return await new Promise(async (resolve, reject) => { resolve() })
}


/**
 * @param {import("axios").Method} method
 * @param {string} endpoint
 * @param {any} data
 * @param {boolean} requireToken
 * @param {import("axios").ResponseType} responseType
 * @return {Promise<import("axios").AxiosResponse<any>>}
 */
export async function ajaxCall(endpoint, responseType = 'json', method = 'get', data = {}, requireToken = true) {
  if (!isAccessTokenValid(requireToken))
    await refreshToken(true);

  return axios({
    method: method,
    url: SERVER_URL + endpoint,
    data: data,
    withCredentials: true,
    headers: { "Content-Type": "application/json", "Authorization": `Bearer ${bearerToken}` },
    responseType: responseType
  })

}


/**
 * @param {string} endpoint
 * @param {object} data
 */
export function LoginAuth(endpoint, data = {}) {
  return axios({
    method: 'post',
    url: SERVER_URL + endpoint,
    data: data,
    withCredentials: true,
    headers: { "Content-Type": "application/json" },
    responseType: 'json'
  }).then(res => {
    let data = res.data.data.tepngUserAccessToken;
    bearerToken = data.token;
    bearerTokenExpiration = data.expiration;
    tepngUserInfo = res.data.data.tepngUser;
  })
}

/**
 * @param {string} endpoint
 * @param {object} data
 */
export function ConsentToCookies(endpoint, data = {}) {
  return axios({
    method: 'patch',
    url: SERVER_URL + endpoint,
    data: data,
    withCredentials: true,
    headers: { "Content-Type": "application/json", "Authorization": `Bearer ${bearerToken}` },
    responseType: 'json'
  }).then(res => {
    tepngUserInfo = res.data.data;
  })
}

/**
 * @param {string} id
 * @param {string} show
 * @param {string} hide
 */
export function htmlinputType(id, show, hide) {
  /** @type HTMLInputElement */
  let input = document.querySelector("#" + id);

  /** @type HTMLInputElement */
  let btnHide = document.querySelector("#" + hide);

  /** @type HTMLInputElement */
  let btnShow = document.querySelector("#" + show);

  if (input.type === "password") {
    input.type = "text";
  }
  else {
    input.type = "password";
  }

  btnShow.style.display = 'block'
  btnHide.style.display = 'none'
}

/**
 * @param {string} type
 * @param {string} message
 */
export function tinyAlert(type, message) {
  switch (type) {
    case 'info':
      NotificationManager.info(message, '', 15000);
      break;
    case 'success':
      NotificationManager.success(message, '', 15000);
      break;
    case 'warning':
      NotificationManager.warning(message, '', 15000);
      break;
    case 'error':
      NotificationManager.error(message, '', 15000);
      break;
    default:
      NotificationManager.error(message, '', 15000);
  }
}



export const displayExtendLoginSession = () => {
  MySwal.fire({
    icon: 'info',
    timer: 150000,
    timerProgressBar: true,
    html: `<span style="font-size:14px"><b>Your current session is inactive</b><br /> Click OK to stay signed in.</span>`,
    // footer: '<a href="/faq" style="font-size:12px">Why do I have this issue?</a>',
    showDenyButton: false,
    denyButtonText: `<span>Yes</span>`,
    showConfirmButton: true,
    allowOutsideClick: false,
  })
    .then(function (r) {
      if (!r.isConfirmed)
        logout();
    })
}

const resetTimer = () => {
  if (timerRefno != null) clearTimeout(timerRefno)
  timerRefno = setTimeout(displayExtendLoginSession, (5 * 60 * 1000));
}

export function inactivityTime() {
  document.onmousemove = resetTimer;
  document.onkeydown = resetTimer;
  document.onkeyup = resetTimer;
  document.onclick = resetTimer;
}