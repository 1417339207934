import React, { Component } from 'react';
import SideNav from '../layouts/SideNav';
import TopNav from '../layouts/TopNav';
import PageTitle  from '../layouts/PageTitle';
import PrevIcon from '../layouts/PrevIcon';
import NextIcon from '../layouts/NextIcon';

import { Preloader, ajaxCall, closeAlert, errorAction, confirmPrompt, tinyAlert } from '../helpers/Helpers'


class TepngCompany extends Component {
  componentDidMount() {
    this.GetTepngCompany();
  }

  state = {
    allTepng:[],
    filteredTepng:[],
    currentPageTepng:[],

    numRecordsPerPage: 25,
    currentPage: 1,
    numOfPages: 1,
    form: {
      name:'',
      companyCode:'',
      address:'',
      active:''
    },
  }

  handleFormInputsChange = (e) => { this.setState(prevState => ({ form: { ...prevState.form, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value } })) }

  GetTepngCompany = () => {
    Preloader();    
    ajaxCall('TepngCompany').then(res => {
      closeAlert();  this.setState({ allTepng: res.data.data }, this.filteredTepngTable);
    })
    .catch(error => {
      errorAction(error);
    })
  }

  filteredTepngTable = (e) => {
    let result
    if (e != null) {
      let search = e.target.value.toLowerCase();
      result = this.state.allTepng.filter((x) => x.address.toLowerCase().includes(search) || x.name.toLowerCase().includes(search) || x.companyCode.toLowerCase().includes(search));
    }
    else
      result = this.state.allTepng;

    this.setState({ filteredTepng: result.map((r, i) => ({ ...r, index: i + 1 })) }, this.updateTablePagination);
  }

  updateTablePagination = () => {
    let recordCount = this.state.filteredTepng.length
    let numOfPages = Math.ceil(recordCount / this.state.numRecordsPerPage)
    let currentPage = this.state.currentPage
    if (currentPage < 1) currentPage = 1;
    if (currentPage > numOfPages) currentPage = numOfPages;
    this.setState({ currentPage: currentPage, numOfPages: numOfPages }, this.setRecordsForCurrentPage)
  }

  setRecordsForCurrentPage = () => {
    const offset = (this.state.currentPage - 1) * this.state.numRecordsPerPage;
    this.setState({ currentPageTepng: this.state.filteredTepng.slice(offset, offset + this.state.numRecordsPerPage) });
  }

  setNumRecordsPerPage = (e) => {
    this.setState({ numRecordsPerPage: Number(e.target.value) }, this.updateTablePagination);
  }

  nextPage = (e) => {
    this.setState({ currentPage: Number(this.state.currentPage) + 1 }, this.setRecordsForCurrentPage);
  }

  previousPage = (e) => {
    this.setState({ currentPage: Number(this.state.currentPage) - 1 }, this.setRecordsForCurrentPage);
  }

  DeleteTepng = (id) => {
    Preloader();
    
    ajaxCall("TepngCompany/"+id, 'json', 'delete').then(res => {
      tinyAlert('success','Tepng deleted successfully.'); closeAlert();
      
      let records = this.state.allTepng;
      records.splice(records.findIndex(v => v.id === id), 1);
      this.setState({ allTepng: records}, this.filteredTepngTable);
    })
    .catch(error => {
      errorAction(error);
    })
  }

  UpdateTepng = (data) => {
    Preloader();

    let form = {};
    form.address = data.address;
    form.name = data.name;
    form.companyCode = data.companyCode;
    form.active = data.active === true || data.active === 'Yes' ? true : false;

    ajaxCall("TepngCompany/"+data.id, 'json', 'put', form).then(res => {
      tinyAlert('success','Tepng updated successfully.'); closeAlert();
    })
    .catch(error => {
      errorAction(error);
    })
  }

  AddTepng = () => {
    Preloader();
    let form = {};
    form.address = this.state.form.address;
    form.name = this.state.form.name;
    form.companyCode = this.state.form.companyCode;
    form.active = this.state.form.active === 'Yes' ? true : false;    

    ajaxCall('TepngCompany', 'json', 'post', form ).then(res => {   
      tinyAlert('success','Tepng created successfully.'); closeAlert();
      let data = res.data.data;
      let records = this.state.allTepng;
      records.push(data);
      
      this.setState({ allTepng: records}, this.filteredTepngTable);
      this.setState({ form: {  name:'', companyCode:'', address:'', active:'' } })
    })
    .catch(error => {
      errorAction(error);
    })
  }

  handleChangeInfo = (e, x) => {
    this.setState({
      allTepng: this.state.allTepng.map(g => g.companyCode === x.companyCode ? { ...g, [e.target.name] : e.target.value } : g),
      filteredTepng: this.state.filteredTepng.map(g =>  g.companyCode === x.companyCode ? { ...g, [e.target.name] : e.target.value } : g),
      currentPageTepng: this.state.currentPageTepng.map(g =>  g.companyCode === x.companyCode ? { ...g, [e.target.name] : e.target.value } : g)
    }, this.filteredTepngTable)
  }


  editRecord = (id, status) => {
    return this.state.allTepng.map(item => {
        var temp = Object.assign({}, item);
        if (temp.id === id) {
            temp.active = status;
        }
        return temp;
    });
  }

  EnableTepng = (id) => {
    Preloader();
    
    ajaxCall("TepngCompany/EnableTepngCompany/"+id, 'json', 'patch').then(res => {
      tinyAlert('success',res.data.message); closeAlert();      
           
      let record = this.editRecord(id, true);
      this.setState({ allTepng: record }, this.filteredTepngTable);
    })
    .catch(error => {
      errorAction(error);
    })
  }

  DisableTepng= (id) => {
    Preloader();

    ajaxCall("TepngCompany/DisableTepngCompany/"+id, 'json', 'patch').then(res => {
      tinyAlert('success',res.data.message); closeAlert();      
           
      let record = this.editRecord(id, false);
      this.setState({ allTepng: record }, this.filteredTepngTable);
    })
    .catch(error => {
      errorAction(error);
    })
  }

  render() {
    return (
      
      <div className='mainApp'>
      <div className='container-fluid'>
        <div className='row'>
          <SideNav />
          
          <div className='main-content-wrap'>
            <TopNav />
            <PageTitle pagedata={{page:'Tepng Company',breadcrumb:'Tepng Companies'}}/>

            <div className='container mt-1 mb-5'>
              <div className='row'>
                <div className='col-12 px-4'>
                  <div className='mt-4 mb-4'>

                    <div className='mb-3 mt-1' style={{position:'relative'}}>
                      <input type="text" className="form-control input-font bold" placeholder='Search...' onInput={this.filteredTepngTable}/>
                      <i className='fa fa-search' style={{position:'absolute', right:'20px', top:'12px', color:'#939597'}}></i>
                    </div>

                    <div className='table-responsive'>
                      <table className="table font-12 table-striped table-hover mb-1 table-bordered">
                        <thead className='text-white'>
                          <tr>
                            <th className='text-center'>SN</th>
                            <th>Name</th>
                            <th>Code</th>
                            <th>Address</th>
                            <th className='text-center'>Active</th>
                            <th style={{width:'50px'}}></th>
                            <th style={{width:'50px'}}></th>
                            <th style={{width:'50px'}}></th>
                          </tr>
                        </thead>
                        <tbody className='font-12'>
                          {
                            this.state.currentPageTepng.map(object => {
                              return (
                              <tr key={object.index}>
                                <td className='text-center'>{object.index}</td>
                                <td>                                        
                                  <input type="text" name='name' value={object.name} onChange={(e) => this.handleChangeInfo(e,object)} className="form-control input-font p-1" />
                                </td>
                                <td>
                                  <input type="text" name='companyCode' value={object.companyCode} onChange={(e) => this.handleChangeInfo(e,object)} className="form-control input-font p-1" />
                                </td>

                                <td>
                                  <input type="text" name='address' value={object.address} onChange={(e) => this.handleChangeInfo(e, object)} className="form-control input-font p-1" />
                                
                                </td>
                                
                                <td className='text-center'>{object.active ? 'Yes' : 'No'}</td>
                                
                                <td><button className='btn btn-sm btn-info bold border-radius-sm checkBtn' onClick={() => confirmPrompt("Do you want to update company?").then( (r) => r.isConfirmed ? this.UpdateTepng(object) : false)}>Update</button></td>

                                <td><button className='btn btn-sm btn-danger bold border-radius-sm' onClick={() => confirmPrompt("Do you want to delete company?").then( (r) => r.isConfirmed ? this.DeleteTepng(object.id) : false)}>Delete</button></td>
                                
                                <td><button className={object.active ? 'btn-danger btn btn-sm bold border-radius-sm' : 'btn-success btn btn-sm bold border-radius-sm'} onClick={() => object.active ? this.DisableTepng(object.id) : this.EnableTepng(object.id) }>{object.active ? 'Disable' : 'Enable'}</button></td>
                              </tr>
                              )
                            })
                          }

                          <tr>
                            <td className='text-center'></td>
                            <td>                                        
                              <input type="text" placeholder='Company Name' name='name' value={this.state.form.name} onChange={this.handleFormInputsChange} className="form-control input-font p-1" />
                            </td>
                            <td>
                              <input type="text" placeholder='Company Code' name='companyCode' value={this.state.form.companyCode} onChange={this.handleFormInputsChange} className="form-control input-font p-1" />
                            </td>

                            <td>
                              <input type="text" placeholder='Address' name='address' value={this.state.form.address} onChange={this.handleFormInputsChange} className="form-control input-font p-1" />
                            
                            </td>
                            <td>                                     
                              <select className="form-control input-font p-1 form-select" value={this.state.form.active ? 'Yes' : 'No'} name='active' onChange={this.handleFormInputsChange} >
                                <option value='Yes'>Yes</option>
                                <option value='No'>No</option>
                              </select>
                            </td>
                            
                            <td><button className='btn btn-sm btn-success bold border-radius-sm form-control' onClick={() => confirmPrompt("Do you want to create company?").then( (r) => r.isConfirmed ? this.AddTepng() : false)}>Create</button></td>
                            {/* <td></td> */}

                          </tr>
                        </tbody>
                      </table>
                    </div>
                    

                    { this.state.allTepng.length === 0 ? <p className='font-12 text-center p-3'>Ops! No record available...</p>: '' }


                    <div className="row mb-2 mt-2">
                      <div className='col-8'></div>
                      <div className='col-1 text-end'>
                        <label className='font-12'>Per Page:</label>
                      </div>
                      <div className='col-1'>
                        <select className='font-12 form-select border-radius-sm' value={this.state.numRecordsPerPage} onChange={this.setNumRecordsPerPage}>
                          <option value='3'>3</option>
                          <option value='5'>5</option>
                          <option value='10'>10</option>
                          <option value='25'>25</option>
                          <option value='100'>100</option>
                          <option value='250'>250</option>
                        </select>
                      </div>
                      <div className='col-1 p-0'>
                        <button className='btn bg-light font-12 border-radius-sm' disabled={this.state.currentPage === 1} onClick={this.previousPage}><PrevIcon /> Prev</button>
                      </div>
                      <div className='col-1 p-0'>
                        <button className='btn bg-light font-12 border-radius-sm' disabled={this.state.currentPage === this.state.numOfPages} onClick={this.nextPage}>Next <NextIcon /></button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

export default TepngCompany;