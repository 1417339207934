import React, { Component } from 'react';
import SideNav from '../layouts/SideNav';
import TopNav from '../layouts/TopNav';
import PageTitle  from '../layouts/PageTitle';
import PrevIcon from '../layouts/PrevIcon';
import NextIcon from '../layouts/NextIcon';

import { ajaxCall, closeAlert, errorAction, Preloader, tinyAlert } from '../helpers/Helpers'


class FICategory extends Component {
  componentDidMount() {
    this.GetFICategory();
  }

  state = {
    allFICategory:[],
    filteredFICategory:[],
    currentPageFICategory:[],

    numRecordsPerPage: 25,
    currentPage: 1,
    numOfPages: 1,
    form: {
      name:'',
      requiredDocuments:''
    },
  }

  handleFormInputsChange = (e) => { this.setState(prevState => ({ form: { ...prevState.form, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value } })) }

  GetFICategory = () => {
    Preloader();
    
    ajaxCall('FICategory').then(res => {
      closeAlert();
      this.setState({ allFICategory: res.data.data }, this.filteredFITable);
    })
    .catch(error => {
      errorAction(error);
    })
  }
  
  filteredFITable = (e) => {
    let result
    if (e != null) {
      let search = e.target.value.toLowerCase();
      result = this.state.allFICategory.filter((x) => x.name.toLowerCase().includes(search) || x.requiredDocumentsList.join(',').toLowerCase().includes(search));
    }
    else
      result = this.state.allFICategory;

    this.setState({ filteredFICategory: result.map((r, i) => ({ ...r, index: i + 1 })) }, this.updateTablePagination);
  }

  updateTablePagination = () => {
    let recordCount = this.state.filteredFICategory.length
    let numOfPages = Math.ceil(recordCount / this.state.numRecordsPerPage)
    let currentPage = this.state.currentPage
    if (currentPage < 1) currentPage = 1;
    if (currentPage > numOfPages) currentPage = numOfPages;
    this.setState({ currentPage: currentPage, numOfPages: numOfPages }, this.setRecordsForCurrentPage)
  }

  setRecordsForCurrentPage = () => {
    const offset = (this.state.currentPage - 1) * this.state.numRecordsPerPage;
    this.setState({ currentPageFICategory: this.state.filteredFICategory.slice(offset, offset + this.state.numRecordsPerPage) });
  }

  setNumRecordsPerPage = (e) => {
    this.setState({ numRecordsPerPage: Number(e.target.value) }, this.updateTablePagination);
  }

  nextPage = (e) => {
    this.setState({ currentPage: Number(this.state.currentPage) + 1 }, this.setRecordsForCurrentPage);
  }

  previousPage = (e) => {
    this.setState({ currentPage: Number(this.state.currentPage) - 1 }, this.setRecordsForCurrentPage);
  }

  editRecord = (id, status) => {
    return this.state.allFICategory.map(item => {
        var temp = Object.assign({}, item);
        if (temp.id === id) {
            temp.disabled = status;
        }
        return temp;
    });
  }

  EnableFICategory = (id) => {
    Preloader();
    
    ajaxCall("FICategory/EnableFICategory/"+id, 'json', 'patch').then(res => {
      tinyAlert('success','FI Category enabled successfully.'); closeAlert();

      let record = this.editRecord(id,false);

      this.setState({ allFICategory: record }, this.filteredFITable);
    })
    .catch(error => {
      errorAction(error);
    })
  }

  DisableFICategory= (id) => {
    Preloader();

    ajaxCall("FICategory/DisableFICategory/"+id, 'json', 'patch').then(res => {
      tinyAlert('success','FI Category disabled successfully.'); closeAlert();      
      let record = this.editRecord(id, true);
      this.setState({ allFICategory: record }, this.filteredFITable);
    })
    .catch(error => {
      errorAction(error);
    })
  }

  UpdateFICategory = (data) => {
    Preloader();
    let form = {};
    
    form.Name = data.name;
    form.RequiredDocumentsList = data.requiredDocumentsList;
    form.costObjectRequired = data.costObjectRequired === 'Yes' ? true : false;

    ajaxCall("FICategory/"+data.id, 'json', 'put', form).then(res => {
      tinyAlert('success','FI Category updated successfully.'); closeAlert();
    })
    .catch(error => {
      errorAction(error);
    })
  }
  
  AddFICategory = () => {
    Preloader();
    let form = {};
    form.Name = this.state.form.name;
    form.RequiredDocumentsList = this.state.form.requiredDocuments.split(',');
    
    ajaxCall('FICategory', 'json', 'post', form).then(res => {      
      tinyAlert('success','FI Category created successfully.');  closeAlert();    
      let data = res.data.data;
      let records = this.state.allFICategory;
      records.push(data);
      
      this.setState({ allFICategory: records }, this.filteredFITable);
      this.setState(prevState => ({ form: { name:'', requiredDocuments:''} }))
    })
    .catch(error => {
      errorAction(error);
    })
  }

  handleChangeInfo = (e, x) => {
    this.setState({
      allFICategory: this.state.allFICategory.map(g => g.id === x.id ? { ...g, [e.target.name] : e.target.name !== 'requiredDocumentsList' ? e.target.value: e.target.value.split(',') } : g),
      filteredFICategory: this.state.filteredFICategory.map(g =>  g.id === x.id ? { ...g, [e.target.name] : e.target.name !== 'requiredDocumentsList' ? e.target.value:  e.target.value.split(',')} : g),
      currentPageFICategory: this.state.currentPageFICategory.map(g =>  g.id === x.id ? { ...g, [e.target.name] : e.target.name !== 'requiredDocumentsList' ? e.target.value:  e.target.value.split(',')} : g)
    }, this.filteredFITable)
  }


  render() {
    return (
      
      <div className='mainApp'>
      <div className='container-fluid'>
        <div className='row'>
          <SideNav />
          
          <div className='main-content-wrap'>
            <TopNav />
            <PageTitle pagedata={{page:'Non-Po Category',breadcrumb:'Fi Category'}}/>

            <div className='container mt-1 mb-5'>
              <div className='row'>

                <div className='col-12 px-4'>
                  <div className='mt-4 mb-4'>
                    <div className='mb-3 mt-1' style={{position:'relative'}}>
                      <input type="text" className="form-control input-font bold" placeholder='Search...' onInput={this.filteredFITable}/>
                      <i className='fa fa-search' style={{position:'absolute', right:'20px', top:'12px', color:'#939597'}}></i>
                    </div>

                    <div className='table-responsive'>
                      <table className="table font-12 table-striped table-hover mb-1 table-bordered">
                        <thead className='text-white'>
                          <tr>
                            <th style={{width:'20px'}} className='text-center'>SN</th>
                            <th style={{width:'200px'}}>Name</th>
                            <th>Required Documents</th>
                            <th style={{width:'160px'}}>Cost Object Required</th>
                            <th style={{width:'60px'}}>Active</th>
                            <th style={{width:'50px'}}></th>
                            <th style={{width:'50px'}}></th>
                          </tr>
                        </thead>
                        <tbody className='font-12'>
                          {
                            this.state.currentPageFICategory.map(object => {
                              return (
                              <tr key={object.index}>
                                <td className='text-center'>{object.index}</td>
                                <td>                              
                                  <input type="text" name='name' value={object.name} onChange={(e) => this.handleChangeInfo(e,object)} className="form-control input-font p-1" />
                                </td>
                                <td>
                                  <input type="text" name='requiredDocumentsList' value={object.requiredDocumentsList.join(',')} onChange={(e) => this.handleChangeInfo(e,object)} className="form-control input-font p-1" />
                                </td>

                                <td className='text-center'>                                                               
                                  <select className="form-control input-font p-1 form-select" name='costObjectRequired' onChange={(e) => this.handleChangeInfo(e, object)} value={(object.costObjectRequired === true || object.costObjectRequired === 'Yes') ? 'Yes' : 'No'}>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                  </select>
                                </td>
                                <td className='text-center'>{object.disabled ? 'No' : 'Yes'}</td>
                                
                                <td><button className={object.disabled === false ? 'btn-danger btn btn-sm bold border-radius-sm' : 'btn-success btn btn-sm bold border-radius-sm'} onClick={() => object.disabled === false ? this.DisableFICategory(object.id) : this.EnableFICategory(object.id) }>{object.disabled === false ? 'Disable' : 'Enable'}</button></td>  
                                <td><button className='btn btn-sm btn-success bold border-radius-sm' onClick={() => this.UpdateFICategory(object)}>Update</button></td>                                   
                              </tr>
                              )
                            })
                          } 

                          <tr>
                            <td className='text-center'></td>
                            <td>                                        
                              <input type="text" placeholder='Name' name='name' value={this.state.form.name} onChange={this.handleFormInputsChange} className="form-control input-font p-1" />
                            </td>
                            <td>
                              <input type="text" placeholder='i.e: Approved Mission Order,Entity Email Approval,Flight Ticket' name='requiredDocuments' value={this.state.form.requiredDocuments} onChange={this.handleFormInputsChange} className="form-control input-font p-1" />
                            </td>
                            <td><button className='btn btn-sm btn-success bold border-radius-sm form-control' onClick={() => this.AddFICategory()}>Create</button></td>
                          
                            <td> </td>
                            <td> </td>
                            <td> </td>
                            
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    

                    { this.state.allFICategory.length === 0 ? <p className='font-12 text-center p-3'>Ops! No record available...</p>: '' }


                    <div className="row mb-2 mt-2">
                      <div className='col-8'></div>
                      <div className='col-1 text-end'>
                        <label className='font-12'>Per Page:</label>
                      </div>
                      <div className='col-1'>
                        <select className='font-12 form-select border-radius-sm' value={this.state.numRecordsPerPage} onChange={this.setNumRecordsPerPage}>
                          <option value='3'>3</option>
                          <option value='5'>5</option>
                          <option value='10'>10</option>
                          <option value='25'>25</option>
                          <option value='100'>100</option>
                          <option value='250'>250</option>
                        </select>
                      </div>
                      <div className='col-1 p-0'>
                        <button className='btn bg-light font-12 border-radius-sm' disabled={this.state.currentPage === 1} onClick={this.previousPage}><PrevIcon /> Prev</button>
                      </div>
                      <div className='col-1 p-0'>
                        <button className='btn bg-light font-12 border-radius-sm' disabled={this.state.currentPage === this.state.numOfPages} onClick={this.nextPage}>Next <NextIcon /></button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

export default FICategory;