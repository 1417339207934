import React, { Component } from 'react';

import SideNav              from '../layouts/SideNav';
import TopNav               from '../layouts/TopNav';
import PageTitle            from '../layouts/PageTitle';

import { Preloader, ajaxCall, errorAction, closeAlert, formatDate, tinyAlert } from '../helpers/Helpers'


class Settings extends Component {
  state = {
    form:{
      "id": 1,
      "lastVendorDumpUpload": "",
      "lastBankAccountDumpUpload": "",
      "lastSesDumpUpload": "",
      "lastGrDumpUpload": "",
      "lastCockpitDumpUpload": "",
      "lastCostCenterDumpUpload": "",
      "lastWbsDumpUpload": "",
      "invoiceSubmissionCutOffHourLastBusinessDay": 0,
      "vatRate": 0,
      "bankAccountChangeContactEmail": "",
      "vendorChangeContactEmail": "",
      "supportContactEmail": "",
      "allowedFICurrencies": "",
      "draftInvoiceRetentionPeriodDays": 0,
      "invoiceFlaggedAsNotFoundThresholdDays": 0,
      "fiReviewThresholdHours": 0,
      "requireTepngUserPersonalDataAndCookiesConsent": undefined
    }
  }

  // [Event Hooks]#+++++++
  handleFormInputsChange = (e) =>{ this.setState(prevState => ({ form: {  ...prevState.form, [e.target.name] : e.target.type === 'checkbox' ? e.target.checked : e.target.value }}))}

  componentDidMount() {
    this.GetAllSetting();
  }

  GetAllSetting= () =>{
    Preloader()
    ajaxCall('Setting', 'json', 'get', {}, null).then(res => {
      this.setState({ form: res.data.data })
      closeAlert();
    })
    .catch(error => {
      errorAction(error);
    })
  }

  // [functions Setup]#++++
  UpdateSettings = (e) =>{
    e.preventDefault();
    Preloader();
    let data = this.state.form;

    let formData = {
      "invoiceSubmissionCutOffHourLastBusinessDay": data.invoiceSubmissionCutOffHourLastBusinessDay,
      "vatRate": data.vatRate,
      "bankAccountChangeContactEmail": data.bankAccountChangeContactEmail,
      "vendorChangeContactEmail": data.vendorChangeContactEmail,
      "allowedFICurrencies": data.allowedFICurrencies,
      "draftInvoiceRetentionPeriodDays": data.draftInvoiceRetentionPeriodDays,
      "invoiceFlaggedAsNotFoundThresholdDays": data.invoiceFlaggedAsNotFoundThresholdDays,
      "fiReviewThresholdHours": data.fiReviewThresholdHours,
      "RequireTepngUserPersonalDataAndCookiesConsent": data.requireTepngUserPersonalDataAndCookiesConsent === 'No' ? false : true
    };

    ajaxCall('Setting', 'json', 'put', formData ).then(res => {    
      tinyAlert('success','Updatd successfully!'); closeAlert();
    })
    .catch(error => {
      errorAction(error);
    })
  }

  // # UI rendering;
  render() {
    return (
        <div className='mainApp'>
          <div className='container-fluid'>
            <div className='row'>
              <SideNav />
              
              <div className='main-content-wrap'>
                <TopNav />
                <PageTitle pagedata={{page:'Settings',breadcrumb:'Manage Invoice Template'}}/>

                <div className='container-fluid mb-5'>
                  <div className='row'>
                    <div className='col-12 px-4'>

                      <div className="mt-4 bg-white mb-4">
                        <h6 className='py-3 px-4 mb-0 lead-txt'>
                          Settings
                        </h6>
                        <hr className='m-0' />
                        
                        <form className="login-form py-3 px-4" autoComplete="off" method="post" onSubmit={this.UpdateSettings}>
                          <input type="hidden" autoComplete="off" />
                          
                          <div className='row mt-3'>
                            <div className='col-2'>
                              <label className='font-12 bolder'>Allowed FI Currencies: </label>
                            </div>
                            <div className='col-10'>
                              <div className="form-group mb-0">
                                <input type="text" name='allowedFICurrencies' value={this.state.form.allowedFICurrencies} onChange={this.handleFormInputsChange} autoFocus className="form-control input-font" autoComplete="off" required style={{height:'35px'}} />
                              </div> 
                            </div>
                          </div>
                          
                          <div className='row mt-3'>
                            <div className='col-2'>
                              <label className='font-12 bolder'>Bank Account Change Contact Email: </label>
                            </div>
                            <div className='col-10'>
                              <div className="form-group mb-0">
                                <input type="text" name='bankAccountChangeContactEmail' value={this.state.form.bankAccountChangeContactEmail} onChange={this.handleFormInputsChange} autoFocus className="form-control input-font" autoComplete="off" required style={{height:'35px'}} />
                              </div> 
                            </div>
                          </div>
                          
                          <div className='row mt-3'>
                            <div className='col-2'>
                              <label className='font-12 bolder'>Draft Invoice Retention Period Days: </label>
                            </div>
                            <div className='col-10'>
                              <div className="form-group mb-0">
                                <input type="text" name='draftInvoiceRetentionPeriodDays' value={this.state.form.draftInvoiceRetentionPeriodDays} onChange={this.handleFormInputsChange} autoFocus className="form-control input-font" autoComplete="off" required style={{height:'35px'}} />
                              </div> 
                            </div>
                          </div>
                          
                          <div className='row mt-3'>
                            <div className='col-2'>
                              <label className='font-12 bolder'>FI Review Threshold Hours: </label>
                            </div>
                            <div className='col-10'>
                              <div className="form-group mb-0">
                                <input type="text" name='fiReviewThresholdHours' value={this.state.form.fiReviewThresholdHours} onChange={this.handleFormInputsChange} autoFocus className="form-control input-font" autoComplete="off" required style={{height:'35px'}} />
                              </div> 
                            </div>
                          </div>
                          
                          <div className='row mt-3'>
                            <div className='col-2'>
                              <label className='font-12 bolder'>Invoice Flagged As Not Found Threshold Days: </label>
                            </div>
                            <div className='col-10'>
                              <div className="form-group mb-0">
                                <input type="text" name='invoiceFlaggedAsNotFoundThresholdDays' value={this.state.form.invoiceFlaggedAsNotFoundThresholdDays} onChange={this.handleFormInputsChange} autoFocus className="form-control input-font" autoComplete="off" required style={{height:'35px'}} />
                              </div> 
                            </div>
                          </div>
                          
                          <div className='row mt-3'>
                            <div className='col-2'>
                              <label className='font-12 bolder'>Invoice Submission CutOff Hour Last Business Day: </label>
                            </div>
                            <div className='col-10'>
                              <div className="form-group mb-0">
                                <input type="text" name='invoiceSubmissionCutOffHourLastBusinessDay' value={this.state.form.invoiceSubmissionCutOffHourLastBusinessDay} onChange={this.handleFormInputsChange} autoFocus className="form-control input-font" autoComplete="off" required style={{height:'35px'}} />
                              </div> 
                            </div>
                          </div>
                          
                          <div className='row mt-3'>
                            <div className='col-2'>
                              <label className='font-12 bolder'>Last Bank Account Dump Upload: </label>
                            </div>
                            <div className='col-10'>
                              <div className="form-group mb-0">
                                <input type="text" name='lastBankAccountDumpUpload' value={formatDate(this.state.form.lastBankAccountDumpUpload,'')} onChange={this.handleFormInputsChange} autoFocus className="form-control input-font" autoComplete="off" disabled style={{height:'35px'}} />
                              </div> 
                            </div>
                          </div>
                          
                          <div className='row mt-3'>
                            <div className='col-2'>
                              <label className='font-12 bolder'>Last Cockpit Dump Upload: </label>
                            </div>
                            <div className='col-10'>
                              <div className="form-group mb-0">
                                <input type="text" name='lastCockpitDumpUpload' value={formatDate(this.state.form.lastCockpitDumpUpload,'')} onChange={this.handleFormInputsChange} autoFocus className="form-control input-font" autoComplete="off" disabled style={{height:'35px'}} />
                              </div> 
                            </div>
                          </div>
                          
                          <div className='row mt-3'>
                            <div className='col-2'>
                              <label className='font-12 bolder'>Last Cost Center Dump Upload: </label>
                            </div>
                            <div className='col-10'>
                              <div className="form-group mb-0">
                                <input type="text" name='lastCostCenterDumpUpload' value={formatDate(this.state.form.lastCostCenterDumpUpload,'')} onChange={this.handleFormInputsChange} autoFocus className="form-control input-font" autoComplete="off" disabled style={{height:'35px'}} />
                              </div> 
                            </div>
                          </div>
                          
                          <div className='row mt-3'>
                            <div className='col-2'>
                              <label className='font-12 bolder'>Last Gr Dump Upload: </label>
                            </div>
                            <div className='col-10'>
                              <div className="form-group mb-0">
                                <input type="text" name='lastGrDumpUpload' value={formatDate(this.state.form.lastGrDumpUpload,'')} onChange={this.handleFormInputsChange} autoFocus className="form-control input-font" autoComplete="off" disabled style={{height:'35px'}} />
                              </div> 
                            </div>
                          </div>
                          
                          <div className='row mt-3'>
                            <div className='col-2'>
                              <label className='font-12 bolder'>Last SES Dump Upload: </label>
                            </div>
                            <div className='col-10'>
                              <div className="form-group mb-0">
                                <input type="text" name='lastSesDumpUpload' value={formatDate(this.state.form.lastSesDumpUpload,'')} onChange={this.handleFormInputsChange} autoFocus className="form-control input-font" autoComplete="off" disabled style={{height:'35px'}} />
                              </div> 
                            </div>
                          </div>
                          
                          <div className='row mt-3'>
                            <div className='col-2'>
                              <label className='font-12 bolder'>Last Vendor Dump Upload: </label>
                            </div>
                            <div className='col-10'>
                              <div className="form-group mb-0">
                                <input type="text" name='lastVendorDumpUpload' value={formatDate(this.state.form.lastVendorDumpUpload,'')} onChange={this.handleFormInputsChange} autoFocus className="form-control input-font" autoComplete="off" disabled style={{height:'35px'}} />
                              </div> 
                            </div>
                          </div>
                          
                          <div className='row mt-3'>
                            <div className='col-2'>
                              <label className='font-12 bolder'>Last Wbs Dump Upload: </label>
                            </div>
                            <div className='col-10'>
                              <div className="form-group mb-0">
                                <input type="text" name='lastWbsDumpUpload' value={formatDate(this.state.form.lastWbsDumpUpload,'')} onChange={this.handleFormInputsChange} autoFocus className="form-control input-font" autoComplete="off" disabled style={{height:'35px'}} />
                              </div>
                            </div>
                          </div>
                          
                          <div className='row mt-3'>
                            <div className='col-2'>
                              <label className='font-12 bolder'>Require TepngUser Personal Data And Cookies Consent: </label>
                            </div>
                            <div className='col-10'>
                              <div className="form-group mb-0">                                                               
                                <select className="form-control input-font p-2 form-select" name='requireTepngUserPersonalDataAndCookiesConsent' onChange={this.handleFormInputsChange} value={(this.state.form.requireTepngUserPersonalDataAndCookiesConsent === true || this.state.form.requireTepngUserPersonalDataAndCookiesConsent === 'Yes') ? 'Yes' : 'No'}>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                  </select>
                              </div>
                            </div>
                          </div>
                          
                          <div className='row mt-3'>
                            <div className='col-2'>
                              <label className='font-12 bolder'>Support Contact Email: </label>
                            </div>
                            <div className='col-10'>
                              <div className="form-group mb-0">
                                <input type="text" name='supportContactEmail' value={this.state.form.supportContactEmail} onChange={this.handleFormInputsChange} autoFocus className="form-control input-font" autoComplete="off" style={{height:'35px'}} />
                              </div> 
                            </div>
                          </div>
                          
                          <div className='row mt-3'>
                            <div className='col-2'>
                              <label className='font-12 bolder'>Vat Rate: </label>
                            </div>
                            <div className='col-10'>
                              <div className="form-group mb-0">
                                <input type="text" name='vatRate' value={this.state.form.vatRate} onChange={this.handleFormInputsChange} autoFocus className="form-control input-font" autoComplete="off" required style={{height:'35px'}} />
                              </div> 
                            </div>
                          </div>
                          
                          <div className='row mt-3'>
                            <div className='col-2'>
                              <label className='font-12 bolder'>Vendor Change Contact Email: </label>
                            </div>
                            <div className='col-10'>
                              <div className="form-group mb-0">
                                <input type="text" name='vendorChangeContactEmail' value={this.state.form.vendorChangeContactEmail} onChange={this.handleFormInputsChange} autoFocus className="form-control input-font" autoComplete="off" required style={{height:'35px'}} />
                              </div> 
                            </div>
                          </div>
                          

                          <div className='row'>
                            <div className='col-10'></div>
                            <div className='col-2'>
                              <div className="form-group mt-3 mb-5">
                                <button type='submit' className='btn btn-primary font-12 border-radius-sm form-control'>Update</button>
                              </div> 
                            </div>
                          </div>
                        </form>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default Settings;