import React, { Component } from 'react';
import SideNav from '../layouts/SideNav';
import TopNav from '../layouts/TopNav';
import PageTitle  from '../layouts/PageTitle';
import PrevIcon from '../layouts/PrevIcon';
import NextIcon from '../layouts/NextIcon';

import { Preloader, ajaxCall, closeAlert, errorAction, confirmPrompt, tinyAlert } from '../helpers/Helpers'


class TepngUser extends Component {
  componentDidMount() {
    this.GetTepngUser();
  }

  state = {
    allTepngUser:[],
    filteredTepngUser:[],
    currentPageTepngUser:[],

    numRecordsPerPage: 25,
    currentPage: 1,
    numOfPages: 1,
    form: {
      firstName:'',
      lastName:'',
      email:'',
      isAdmin:''
    },
  }

  handleFormInputsChange = (e) => { this.setState(prevState => ({ form: { ...prevState.form, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value } })) }

  GetTepngUser = () => {
    Preloader();    
    ajaxCall('TepngUser').then(res => {
      closeAlert();  this.setState({ allTepngUser: res.data.data }, this.filteredTepngUserTable);
    })
    .catch(error => {
      errorAction(error);
    })
  }

  filteredTepngUserTable = (e) => {
    let result
    if (e != null) {
      let search = e.target.value.toLowerCase();
      result = this.state.allTepngUser.filter((x) => x.email.toLowerCase().includes(search) || x.firstName.includes(search) || x.lastName.includes(search));
    }
    else
      result = this.state.allTepngUser;

    this.setState({ filteredTepngUser: result.map((r, i) => ({ ...r, index: i + 1 })) }, this.updateTablePagination);
  }

  updateTablePagination = () => {
    let recordCount = this.state.filteredTepngUser.length
    let numOfPages = Math.ceil(recordCount / this.state.numRecordsPerPage)
    let currentPage = this.state.currentPage
    if (currentPage < 1) currentPage = 1;
    if (currentPage > numOfPages) currentPage = numOfPages;
    this.setState({ currentPage: currentPage, numOfPages: numOfPages }, this.setRecordsForCurrentPage)
  }

  setRecordsForCurrentPage = () => {
    const offset = (this.state.currentPage - 1) * this.state.numRecordsPerPage;
    this.setState({ currentPageTepngUser: this.state.filteredTepngUser.slice(offset, offset + this.state.numRecordsPerPage) });
  }

  setNumRecordsPerPage = (e) => {
    this.setState({ numRecordsPerPage: Number(e.target.value) }, this.updateTablePagination);
  }

  nextPage = (e) => {
    this.setState({ currentPage: Number(this.state.currentPage) + 1 }, this.setRecordsForCurrentPage);
  }

  previousPage = (e) => {
    this.setState({ currentPage: Number(this.state.currentPage) - 1 }, this.setRecordsForCurrentPage);
  }

  DeleteTepngUser = (id) => {
    Preloader();
    
    ajaxCall("TepngUser/"+id, 'json', 'delete').then(res => {
      tinyAlert('success','Tepng User deleted successfully.'); closeAlert(); 
      let records = this.state.allTepngUser;
      records.splice(records.findIndex(v => v.id === id), 1);
      this.setState({ allTepngUser: records}, this.filteredTepngUserTable);
    })
    .catch(error => {
      errorAction(error);
    })
  }

  UpdateTepngUser = (data) => {
    Preloader();

    let form = {};
    form.email = data.email;
    form.firstName = data.firstName;
    form.lastName = data.lastName;
    form.isAdmin = data.isAdmin === true || data.isAdmin === 'Yes' ? true : false;

    ajaxCall("TepngUser/"+data.id, 'json', 'put', form).then(res => {
      tinyAlert('success','Tepng User updated successfully.'); closeAlert();
    })
    .catch(error => {
      errorAction(error);
    })
  }

  AddTepngUser = () => {
    Preloader();
    let form = {};
    form.email = this.state.form.email;
    form.firstName = this.state.form.firstName;
    form.lastName = this.state.form.lastName;
    form.isAdmin = this.state.form.isAdmin === 'Yes' ? true : false;    

    ajaxCall('TepngUser', 'json', 'post', form ).then(res => {   
      tinyAlert('success','Tepng User created successfully.'); closeAlert();
      let data = res.data.data;
      let records = this.state.allTepngUser;
      records.push(data);
      
      this.setState({ allTepngUser: records}, this.filteredTepngUserTable);

      this.setState(prevState => ({ form: { firstName:'', lastName:'', email:'', isAdmin:'' } }))
    })
    .catch(error => {
      errorAction(error);
    })
  }

  handleChangeInfo = (e, x) => {
    this.setState({
      allTepngUser: this.state.allTepngUser.map(g => g.email === x.email ? { ...g, [e.target.name] : e.target.value } : g),
      filteredTepngUser: this.state.filteredTepngUser.map(g =>  g.email === x.email ? { ...g, [e.target.name] : e.target.value } : g),
      currentPageTepngUser: this.state.currentPageTepngUser.map(g =>  g.email === x.email ? { ...g, [e.target.name] : e.target.value } : g)
    }, this.filteredTepngUserTable)
  }


  render() {
    return (
      
      <div className='mainApp'>
      <div className='container-fluid'>
        <div className='row'>
          <SideNav />
          
          <div className='main-content-wrap'>
            <TopNav />
            <PageTitle pagedata={{page:'Tepng Users',breadcrumb:'Tepng Users'}}/>

            <div className='container mt-1 mb-5'>
              <div className='row'>
                <div className='col-12 px-4'>
                  <div className='mt-4 mb-4'>

                    <div className='mb-3 mt-1' style={{position:'relative'}}>
                      <input type="text" className="form-control input-font bold" placeholder='Search...' onInput={this.filteredTepngUserTable}/>
                      <i className='fa fa-search' style={{position:'absolute', right:'20px', top:'12px', color:'#939597'}}></i>
                    </div>

                    <div className='table-responsive'>
                      <table className="table font-12 table-striped table-hover mb-1 table-bordered">
                        <thead className='text-white'>
                          <tr>
                            <th className='text-center'>SN</th>
                            <th>First Name</th>
                            <th>Last Name</th>
                            <th>Email</th>
                            <th>Is Admin</th>
                            <th>Has Consent</th>
                            <th style={{width:'50px'}}></th>
                            <th style={{width:'50px'}}></th>
                          </tr>
                        </thead>
                        <tbody className='font-12'>
                          {
                            this.state.currentPageTepngUser.map(object => {
                              return (
                              <tr key={object.index}>
                                <td className='text-center'>{object.index}</td>
                                <td>                                        
                                  <input type="text" name='firstName' value={object.firstName} onChange={(e) => this.handleChangeInfo(e,object)} className="form-control input-font p-1" />
                                </td>
                                <td>
                                  <input type="text" name='lastName' value={object.lastName} onChange={(e) => this.handleChangeInfo(e,object)} className="form-control input-font p-1" />
                                </td>

                                <td>
                                  <input type="text" name='email' value={object.email} onChange={(e) => this.handleChangeInfo(e, object)} className="form-control input-font p-1" />
                                
                                </td>

                                <td>                                     
                                  <select className="form-control input-font p-1 form-select" name='isAdmin' onChange={(e) => this.handleChangeInfo(e, object)} value={(object.isAdmin === true || object.isAdmin === 'Yes') ? 'Yes' : 'No'}>
                                    <option value='Yes'>Yes</option>
                                    <option value='No'>No</option>
                                  </select>
                                </td>

                                <td>
                                  {object.personalDataAndCookiesConsented ? 'Yes' : 'No'}
                                </td>
                                
                                <td><button className='btn btn-sm btn-success bold border-radius-sm' onClick={() => confirmPrompt("Do you want to update user?").then( (r) => r.isConfirmed ? this.UpdateTepngUser(object) : false)}>Update</button></td>

                                <td><button className='btn btn-sm btn-danger bold border-radius-sm' onClick={() => confirmPrompt("Do you want to delete user?").then( (r) => r.isConfirmed ? this.DeleteTepngUser(object.id) : false)}>Delete</button></td>
                              </tr>
                              )
                            })
                          }

                          <tr>
                            <td className='text-center'></td>
                            <td>                                        
                              <input type="text" placeholder='First Name' name='firstName' value={this.state.form.firstName} onChange={this.handleFormInputsChange} className="form-control input-font p-1" />
                            </td>
                            <td>
                              <input type="text" placeholder='Last Name' name='lastName' value={this.state.form.lastName} onChange={this.handleFormInputsChange} className="form-control input-font p-1" />
                            </td>

                            <td>
                              <input type="text" placeholder='Email' name='email' value={this.state.form.email} onChange={this.handleFormInputsChange} className="form-control input-font p-1" />
                            
                            </td>
                            <td>                                     
                              <select className="form-control input-font p-1 form-select" value={this.state.form.isAdmin ? 'Yes' : 'No'} name='isAdmin' onChange={this.handleFormInputsChange} >
                                <option value='Yes'>Yes</option>
                                <option value='No'>No</option>
                              </select>
                            </td>
                            
                            <td><button className='btn btn-sm btn-success bold border-radius-sm form-control' onClick={() => confirmPrompt("Do you want to create user?").then( (r) => r.isConfirmed ? this.AddTepngUser() : false)}>Create</button></td>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    

                    { this.state.allTepngUser.length === 0 ? <p className='font-12 text-center p-3'>Ops! No record available...</p>: '' }


                    <div className="row mb-2 mt-2">
                      <div className='col-8'></div>
                      <div className='col-1 text-end'>
                        <label className='font-12'>Per Page:</label>
                      </div>
                      <div className='col-1'>
                        <select className='font-12 form-select border-radius-sm' value={this.state.numRecordsPerPage} onChange={this.setNumRecordsPerPage}>
                          <option value='3'>3</option>
                          <option value='5'>5</option>
                          <option value='10'>10</option>
                          <option value='25'>25</option>
                          <option value='100'>100</option>
                          <option value='250'>250</option>
                        </select>
                      </div>
                      <div className='col-1 p-0'>
                        <button className='btn bg-light font-12 border-radius-sm' disabled={this.state.currentPage === 1} onClick={this.previousPage}><PrevIcon /> Prev</button>
                      </div>
                      <div className='col-1 p-0'>
                        <button className='btn bg-light font-12 border-radius-sm' disabled={this.state.currentPage === this.state.numOfPages} onClick={this.nextPage}>Next <NextIcon /></button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

export default TepngUser;