import React, { Component } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { bearerToken, refreshToken, Preloader, closeAlert, tepngUserInfo, inactivityTime } from './helpers/Helpers';
import { NotificationContainer } from 'react-notifications';

import Login from './views/authentication/LoginTepngUser';
import Policy from './views/authentication/Policy';
import NoRouteMatch from './views/authentication/NoRouteMatch';
import Vendors from './views/Vendors';
import VendorInfo from './views/VendorInfo';
import Invoices from './views/Invoices';
import TepngUsers from './views/TepngUsers';
import TepngCompany from './views/TepngCompany';
import TepngUserInfo from './views/TepngUserInfo';
import FICategory from './views/FICategory';
import FIDepartment from './views/FIDepartment';
import ViewInvoice from './views/ViewInvoice';
import Settings from './views/Settings';
import DemoVendor from './views/DemoVendor';


function ProtectedRoute({ children }) {
  let location = useLocation();

  if (bearerToken === "" || bearerToken === null) {
    let redirectpath = location.pathname + location.search;
    localStorage.setItem('loginRedirectedFromUrl', redirectpath);
    window.location.href = '/login-tepnguser';
  }
  else {
    if (tepngUserInfo.personalDataAndCookiesConsented){
      inactivityTime();
      return children
    }
    else{
      let redirectpath = location.pathname + location.search;
      localStorage.setItem('loginRedirectedFromUrl', redirectpath);
      window.location.href = '/policy';
    }
  }
};


class App extends Component {
  state = {
    pageMounted: false
  }

  componentDidMount() {
    let self = this
    Preloader();  
    refreshToken(false).then(res => {
      closeAlert();
      self.setState({ pageMounted: true });
    })
  }

  render() {
    return (
      <Router>
        <div>
          {this.state.pageMounted ?
            <Routes>
              <Route path='/login-tepnguser' element={<Login />} />
              <Route path='/policy' element={<Policy />} />

              <Route path='/' element={<ProtectedRoute><Invoices /></ProtectedRoute>} />


              <Route path='/vendors' element={<ProtectedRoute><Vendors /></ProtectedRoute>} />
              <Route path='/vendor-info/:id' element={<ProtectedRoute><VendorInfo /></ProtectedRoute>} />


              <Route path='/invoices' element={<ProtectedRoute><Invoices /></ProtectedRoute>} />
              <Route path='/view-invoice/:id' element={<ProtectedRoute><ViewInvoice /></ProtectedRoute>} />

              <Route path='/tepngusers' element={<ProtectedRoute><TepngUsers /></ProtectedRoute>} />
              <Route path='/tepngcompany' element={<ProtectedRoute><TepngCompany /></ProtectedRoute>} />
              <Route path='/tepnguser-info' element={<ProtectedRoute><TepngUserInfo /></ProtectedRoute>} />
              <Route path='/fi-category' element={<ProtectedRoute><FICategory /></ProtectedRoute>} />
              <Route path='/fi-department' element={<ProtectedRoute><FIDepartment /></ProtectedRoute>} />
              <Route path='/settings' element={<ProtectedRoute><Settings /></ProtectedRoute>} />
              <Route path='/demovendor' element={<ProtectedRoute><DemoVendor /></ProtectedRoute>} />

              <Route path="*" element={<NoRouteMatch />} />
            </Routes>
            : ''
          }

          <NotificationContainer />
        </div>
      </Router>
    );
  }
}

export default App;