import React, { Component } from 'react';
import SideNav from '../layouts/SideNav';
import TopNav from '../layouts/TopNav';
import PageTitle  from '../layouts/PageTitle';
import PrevIcon from '../layouts/PrevIcon';
import NextIcon from '../layouts/NextIcon';

import { ajaxCall, closeAlert, confirmPrompt, errorAction, Preloader, tinyAlert } from '../helpers/Helpers'


class DemoVendor extends Component {
  componentDidMount() {
    this.GetDemoVendors();
  }

  state = {
    DemoVendors:[],
    filteredDemoVendors:[],
    currentPageDemoVendors:[],

    numRecordsPerPage: 25,
    currentPage: 1,
    numOfPages: 1,
    form: {
      name:'',
      emails:''
    },
  }

  handleFormInputsChange = (e) => { this.setState(prevState => ({ form: { ...prevState.form, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value } })) }

  GetDemoVendors = () => {
    Preloader();
    
    ajaxCall('Vendor/DemoVendors').then(res => {
      closeAlert();
      this.setState({ DemoVendors: res.data.data }, this.filteredDemoVendors);
    })
    .catch(error => {
      errorAction(error);
    })
  }

  filteredDemoVendors = (e) => {
    let result
    if (e != null) {
      let search = e.target.value.toLowerCase();
      result = this.state.DemoVendors.filter((x) => x.name.toLowerCase().includes(search) || x.vendorCode.toLowerCase().includes(search));
    }
    else
      result = this.state.DemoVendors;

    this.setState({ filteredDemoVendors: result.map((r, i) => ({ ...r, index: i + 1 })) }, this.updateTablePagination);
  }

  updateTablePagination = () => {
    let recordCount = this.state.filteredDemoVendors.length
    let numOfPages = Math.ceil(recordCount / this.state.numRecordsPerPage)
    let currentPage = this.state.currentPage
    if (currentPage < 1) currentPage = 1;
    if (currentPage > numOfPages) currentPage = numOfPages;
    this.setState({ currentPage: currentPage, numOfPages: numOfPages }, this.setRecordsForCurrentPage)
  }

  setRecordsForCurrentPage = () => {
    const offset = (this.state.currentPage - 1) * this.state.numRecordsPerPage;
    this.setState({ currentPageDemoVendors: this.state.filteredDemoVendors.slice(offset, offset + this.state.numRecordsPerPage) });
  }

  setNumRecordsPerPage = (e) => {
    this.setState({ numRecordsPerPage: Number(e.target.value) }, this.updateTablePagination);
  }

  nextPage = (e) => {
    this.setState({ currentPage: Number(this.state.currentPage) + 1 }, this.setRecordsForCurrentPage);
  }

  previousPage = (e) => {
    this.setState({ currentPage: Number(this.state.currentPage) - 1 }, this.setRecordsForCurrentPage);
  }

  DeleteDemoVendor = (id) => {
    Preloader();

    let vendorCode = id.replace(/\D/g,'');
    var incrementvalue = +vendorCode;
    let code =  ("000000" + incrementvalue).slice(-5)

    ajaxCall("Vendor/DeleteDemoVendor/"+code, 'json', 'delete').then(res => {
      tinyAlert('success',"Demo vendor deleted successfully"); 
      this.GetDemoVendors();
    })
    .catch(error => {
      errorAction(error);
    })
  }

  
  AddDemoVendor = (e) => {
    Preloader();
    let form = {
      "currencies": [
        "NGN",
        "USD",
        "EUR"
      ],
      "nofOfItemsPerSes": 3,
      "noOfGrPerMaterialPO": 3,
      "noOfItemsPerGr": 3,
      "noOfMaterialPOs": 3,
      "noOfServicePOs": 3,
      "noOfSesPerServicePO": 3,
      "subsidiaryId": "NG01",
    };

    if(this.state.DemoVendors.length > 0){
      let vendorCode = this.state.DemoVendors[this.state.DemoVendors.length - 1].vendorCode.replace(/\D/g,'');
      var incrementvalue = (+vendorCode) + 1;
      form.vendorCode =  ("00000" + incrementvalue).slice(-5)
    }
    else{
      form.vendorCode =  ("00000" + 1).slice(-5)
    }

    form.vendorName = this.state.form.name;
    form.emails = this.state.form.emails.split(',');

    ajaxCall('Vendor/CreateDemoVendor', 'json', 'post', form).then(res => {
      tinyAlert('success','Demo vendor created successfully.');
      this.GetDemoVendors();
    })
    .catch(error => {
      errorAction(error);
    })
  }

  handleChangeInfo = (e, x) => {
    this.setState({
      allFIDemoVendors: this.state.allDemoVendors.map(g => g.id === x.id ? { ...g, [e.target.name] : e.target.name !== 'fiReviewersList' ? e.target.value: e.target.value.replaceAll(' ','').split(',') } : g),
      filteredDemoVendors: this.state.filteredDemoVendors.map(g =>  g.id === x.id ? { ...g, [e.target.name] : e.target.name !== 'fiReviewersList' ? e.target.value:  e.target.value.replaceAll(' ','').split(',')} : g),
      currentPageDemoVendors: this.state.currentPageDemoVendors.map(g =>  g.id === x.id ? { ...g, [e.target.name] : e.target.name !== 'fiReviewersList' ? e.target.value:  e.target.value.replaceAll(' ','').split(',')} : g)
    }, this.filteredDemoVendors)
  }


  render() {
    return (
      
      <div className='mainApp'>
      <div className='container-fluid'>
        <div className='row'>
          <SideNav />
          
          <div className='main-content-wrap'>
            <TopNav />
            <PageTitle pagedata={{page:'DEMO Vendor',breadcrumb:' DEMO Vendor'}} />

            <div className='container mt-1 mb-5'>
              <div className='row'>

                <div className='col-12 px-4'>
                  <div className='mt-4 mb-4'>
                    <div className='mb-3 mt-1' style={{position:'relative'}}>
                      <input type="text" className="form-control input-font bold" placeholder='Search...' onInput={this.filteredDemoVendors}/>
                      <i className='fa fa-search' style={{position:'absolute', right:'20px', top:'12px', color:'#939597'}}></i>
                    </div>

                    <div className='table-responsive'>
                      <table className="table font-12 table-striped table-hover mb-1 table-bordered">
                        <thead className='text-white'>
                          <tr>
                            <th style={{width:'20px'}} className='text-center'>SN</th>
                            <th style={{width:'200px'}}>Code</th>
                            <th>Name</th>
                            <th style={{width:'60px'}}>Active</th>
                            <th style={{width:'50px'}}></th>
                          </tr>
                        </thead>
                        <tbody className='font-12'>
                          {
                            this.state.currentPageDemoVendors.map(object => {
                              return (
                              <tr key={object.index}>
                                <td className='text-center'>{object.index}</td>
                                <td>                        
                                  <input type="text" name='dept' value={object.vendorCode} className="form-control input-font p-1" disabled />
                                </td>
                                <td>                        
                                  <input type="text" name='dept' value={object.name} className="form-control input-font p-1" disabled />
                                </td>

                                <td className='text-center'>{object.active ? 'Yes' : 'No'}</td>
                                
                                <td>
                                  <button className='btn-danger btn btn-sm bold border-radius-sm'   onClick={() => confirmPrompt(`Do you want to delete vendor?`).then( (r) => r.isConfirmed ? this.DeleteDemoVendor(object.vendorCode) : false)} >Delete</button></td>
                              </tr>
                              )
                            })
                          }
                        </tbody>
                      </table>
                    </div>
                    
                    <div className="row mb-2 mt-2">
                      <div className='col-8'></div>
                      <div className='col-1 text-end'>
                        <label className='font-12'>Per Page:</label>
                      </div>
                      <div className='col-1'>
                        <select className='font-12 form-select border-radius-sm' value={this.state.numRecordsPerPage} onChange={this.setNumRecordsPerPage}>
                          <option value='3'>3</option>
                          <option value='5'>5</option>
                          <option value='10'>10</option>
                          <option value='25'>25</option>
                          <option value='100'>100</option>
                          <option value='250'>250</option>
                        </select>
                      </div>
                      <div className='col-1 p-0'>
                        <button className='btn bg-light font-12 border-radius-sm' disabled={this.state.currentPage === 1} onClick={this.previousPage}><PrevIcon /> Prev</button>
                      </div>
                      <div className='col-1 p-0'>
                        <button className='btn bg-light font-12 border-radius-sm' disabled={this.state.currentPage === this.state.numOfPages} onClick={this.nextPage}>Next <NextIcon /></button>
                      </div>
                    </div>

                    <div className='row mb-2 mt-2'>
                      <div className='col-12 bg-white py-5 px-5'>
                        <form method='post'>                          
                            <div className='h5'>Create Demo Vendor</div>
                            <div className='mb-3'>   
                              <label className='font-12'>Vendor Name</label>                                     
                              <input type="text" placeholder='Vendor Name' name='name' value={this.state.form.name} onChange={this.handleFormInputsChange} className="form-control input-font p-1" />
                            </div>
                            <div className='mb-3'>
                              <label className='font-12'>Vendor emails</label>    
                              <input type="text" placeholder='Email separated by comma' name='emails' value={this.state.form.emails} onChange={this.handleFormInputsChange} className="form-control input-font p-1" />
                            </div>
                            <div><button type='button' className='btn btn-sm btn-success bold border-radius-sm' onClick={() => this.AddDemoVendor()}>Create</button></div>
                          </form>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

export default DemoVendor;