import React, { Component } from 'react';

class Copyright extends Component {
  render() {
    return (
      <div className="font-12 text-center copyright-area">  
          <hr />                      
          <span>Copyright &copy;2022 Total Energies | All right reserved</span>
      </div>
    );
  }
}

export default Copyright;