import React, { Component } from 'react';
import withRouter from '../../src/WithRouter';
import SideNav from '../layouts/SideNav';
import TopNav from '../layouts/TopNav';
import PageTitle  from '../layouts/PageTitle';
import { Preloader, ajaxCall, closeAlert, errorAction, confirmPrompt } from '../helpers/Helpers'

class VendorInfo extends Component {
  componentDidMount() {
    this.GetVendor();
  }

  state = {
    vendor:{},
    vendorUsers:[]
  }

  GetVendor = () => {
    Preloader();

    ajaxCall("Vendor/"+this.props.routerParams.id).then(res => {
      this.setState({ vendor : res.data.data }, this.GetVendorUsers);
    })
    .catch(error => {
      errorAction(error)
    })
  }

  GetVendorUsers = () => {
    ajaxCall("Vendor/GetVendorUsersForVendor/"+this.props.routerParams.id).then(res => {
      closeAlert();
      this.setState({ vendorUsers: res.data.data });
    })
    .catch(error => {
      errorAction(error)
    })
  }

  EnableUser = (id) => {
    Preloader();

    ajaxCall("Vendor/EnableVendorUser?email="+id, 'json', 'patch').then(res => {
      closeAlert();
      // this.GetVendor();
    })
    .catch(error => {
      errorAction(error);
    })
  }

  DisableUser = (id) => {
    Preloader();

    ajaxCall("Vendor/DisableVendorUser?email="+id, 'json', 'patch').then(res => {
      closeAlert();
      // this.GetVendor();
    })
    .catch(error => {
      errorAction(error);
    })
  }


  render() {
    return (

      <div className='mainApp'>
      <div className='container-fluid'>
        <div className='row'>
          <SideNav />

          <div className='main-content-wrap'>
            <TopNav />
            <PageTitle pagedata={{page:'Vendor',breadcrumb:'Vendor Information'}}/>

            <div className='container mt-1 mb-5'>
              <div className='row'>
                <div className='col-12 px-4'>
                  <div className='mt-4 mb-4'>
                    <div className='table-responsive mb-3'>
                      <table className="table font-12 table-striped table-hover mb-1 table-bordered">
                        <tbody className='font-12'>
                          <tr>
                            <td>Name:  </td>
                            <td>{this.state.vendor.name} </td>
                          </tr>
                          <tr>
                            <td>Vendor Code: </td>
                            <td>{this.state.vendor.vendorCode} </td>
                          </tr>
                          <tr>
                            <td>Phone: </td>
                            <td>{this.state.vendor.phone} </td>
                          </tr>
                          <tr>
                            <td>Address: </td>
                            <td>{this.state.vendor.address}</td>
                          </tr>
                          <tr>
                            <td>Invoice Color: </td>
                            <td>{this.state.vendor.invoiceColorCodeHex}</td>
                          </tr>
                          <tr>
                            <td>Part Invoicing: </td>
                            <td>{this.state.vendor.partInvoicingEnabled ? 'Yes' : 'No' }</td>
                          </tr>
                          <tr>
                            <td>TIN No.: </td>
                            <td>{this.state.vendor.tinNo} </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className='table-responsive'>
                      <table className="table font-12 table-striped table-hover mb-1 table-bordered">
                        <thead className='text-white'>
                          <tr>
                            <th className='text-center'>SN</th>
                            <th>Email Address</th>
                            <th>Vendor Codes</th>
                            <th className='text-center'>Active</th>
                            <th className='text-center'>View</th>
                          </tr>
                        </thead>
                        <tbody className='font-12'>
                        {
                          this.state.vendorUsers.map((object,i) => {
                            return (
                            <tr key={i}>
                              <td className='text-center'>{i+1}</td>
                              <td>{object.email}</td>
                              <td>{object.vendorCodes}</td>
                              <td className='text-center'>{ !object.accountDisabled ?  'Yes' :  'No' }</td>
                              <td className='text-center'>
                                {
                                  !object.accountDisabled ?
                                  <button disabled={object.status === 'Submitted'} className='btn btn-sm btn-danger bold border-radius-sm' onClick={() => confirmPrompt("Do you want to disable user?").then( (r) => r.isConfirmed ? this.DisableUser(object.email) : false)}>Disable</button>
                                  :
                                  <button disabled={object.status === 'Submitted'} className='btn btn-sm btn-success bold border-radius-sm' onClick={() => confirmPrompt("Do you want to enable user?").then( (r) => r.isConfirmed ? this.EnableUser(object.email) : false)}>Enable</button>
                                }
                              </td>
                            </tr>
                            )
                          })
                        }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

export default withRouter(VendorInfo);