import React, { Component } from 'react';
import Select from 'react-select'
// import { Link } from 'react-router-dom';
import withRouter from '../../src/WithRouter';
import SideNav from '../layouts/SideNav';
import TopNav from '../layouts/TopNav';
import PageTitle from '../layouts/PageTitle';
import { ajaxCall, closeAlert, errorAction, confirmPrompt, errorAlert, successAlert, FRONTEND_URL, Preloader, alertPrompt, tinyAlert } from '../helpers/Helpers'
import DOMPurify from 'dompurify';

class ViewInvoice extends Component {
  componentDidMount() {
    this.GetInvoiceByID();
  }

  state = {
    invoice: {},
    InvoicePDF: '',
    InvoiceSupportingPDF: '',

    FIDocuments: [],


    FIExtraDocuments: [],
    FIUploadedDocuments: [],
    FIDepartment: [],
    form: {
      review: '',
      fiDepartment: { value: '', name: 'fiDepartment', label: '' }
    },
    status: '',
    Stepper: [],

    invoiceType: '',
    categoryID: '',
    origin: '',
    invoiceRecord: {}
  }

  handleFormInputsChange = (e) => { this.setState(prevState => ({ form: { ...prevState.form, [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value } })) }
  handleFormSelectChange = (e) => { this.setState(prevState => ({ form: { ...prevState.form, [e.name]: e } })) }


  GetInvoiceByID = () => {
    Preloader();
    ajaxCall('Invoice/' + this.props.routerParams.id, 'json').then(res => {
      let data = res.data.data;

      this.setState({ invoice: data }, this.GenerateInvoicePdf);

      this.GetFIDepartment();
    })
      .catch(error => {
        errorAction(error)
      })
  }

  GenerateInvoicePdf = () => {
    Preloader();
    ajaxCall("Invoice/GetInvoicePdf/" + this.state.invoice.id, 'blob').then(res => {
      this.setState({ InvoicePDF: URL.createObjectURL(res.data) });

      if (this.state.invoice.invoiceType === 'Non-PO') {
        this.createStepperNonPO();
        this.GetFIInvoiceDocuments();
        this.DownloadAllDocument();
      }
      else {
        this.createStepperPO();
        closeAlert();
      }
    })
      .catch(error => { errorAction(error) })
  }

  createStepperPO = () => {
    let i = ['Draft', 'Submitted', 'Not Found', 'Undergoing Pocessing', 'Awaiting Payment', 'Paid', 'Rejected', 'Rejected by Admin'];
    let a = ['', 'complete', 'current', 'terminate', 'success'];
    let stepper = [];

    switch (this.state.invoice.status) {
      case 'Draft': stepper = [{ k: i[0], v: a[2] }, { k: i[1], v: a[0] }, { k: i[3], v: a[0] }, { k: i[4], v: a[0] }, { k: i[5], v: a[0] }]; break;
      case 'Submitted': stepper = [{ k: i[0], v: a[1] }, { k: i[1], v: a[2] }, { k: i[3], v: a[0] }, { k: i[4], v: a[0] }, { k: i[5], v: a[0] }]; break;
      case 'Undergoing Pocessing': stepper = [{ k: i[0], v: a[1] }, { k: i[1], v: a[1] }, { k: i[3], v: a[2] }, { k: i[4], v: a[0] }, { k: i[5], v: a[0] }]; break;
      case 'Awaiting Payment': stepper = [{ k: i[0], v: a[1] }, { k: i[1], v: a[1] }, { k: i[3], v: a[1] }, { k: i[4], v: a[2] }, { k: i[5], v: a[0] }]; break;
      case 'Paid': stepper = [{ k: i[0], v: a[1] }, { k: i[1], v: a[1] }, { k: i[3], v: a[1] }, { k: i[4], v: a[1] }, { k: i[5], v: a[4] }]; break;
      case 'Not Found': stepper = [{ k: i[0], v: a[1] }, { k: i[1], v: a[1] }, { k: i[2], v: a[3] }]; break;
      case 'Rejected': stepper = [{ k: i[0], v: a[1] }, { k: i[1], v: a[1] }, { k: i[3], v: a[1] }, { k: i[6], v: a[3] }]; break;
      case 'Rejected by Admin': stepper = [{ k: i[0], v: a[1] }, { k: i[7], v: a[3] }]; break;
      default: stepper = [];
    }

    this.setState({ Stepper: stepper });
  }

  createStepperNonPO = () => {
    let i = ['', 'Draft', 'Awaiting Technical Rep. Review', 'Accepted by Technical Rep.', 'Rejected by Technical Rep.', 'Submitted', 'Not Found', 'Undergoing Processing', 'Awaiting Payment', 'Paid', 'Rejected', 'Rejected by Admin'];
    let a = ['', 'complete', 'current', 'terminate', 'success'];
    let stepper = [];

    switch (this.state.invoice.status) {
      case 'Draft': stepper = [{ k: i[1], v: a[2] }, { k: i[2], v: a[0] }, { k: i[3], v: a[0] }, { k: i[5], v: a[0] }, { k: i[7], v: a[0] }, { k: i[8], v: a[0] }, { k: i[9], v: a[0] }]; break;
      case 'Awaiting Technical Rep. Review': stepper = [{ k: i[1], v: a[1] }, { k: i[2], v: a[2] }, { k: i[3], v: a[0] }, { k: i[5], v: a[0] }, { k: i[7], v: a[0] }, { k: i[8], v: a[0] }, { k: i[9], v: a[0] }]; break;
      case 'Accepted by Technical Rep.': stepper = [{ k: i[1], v: a[1] }, { k: i[2], v: a[1] }, { k: i[3], v: a[2] }, { k: i[5], v: a[0] }, { k: i[7], v: a[0] }, { k: i[8], v: a[0] }, { k: i[9], v: a[0] }]; break;
      case 'Submitted': stepper = [{ k: i[1], v: a[1] }, { k: i[2], v: a[1] }, { k: i[3], v: a[1] }, { k: i[5], v: a[2] }, { k: i[7], v: a[0] }, { k: i[8], v: a[0] }, { k: i[9], v: a[0] }]; break;
      case 'Undergoing Processing': stepper = [{ k: i[1], v: a[1] }, { k: i[2], v: a[1] }, { k: i[3], v: a[1] }, { k: i[5], v: a[1] }, { k: i[7], v: a[2] }, { k: i[8], v: a[0] }, { k: i[9], v: a[0] }]; break;
      case 'Awaiting Payment': stepper = [{ k: i[1], v: a[1] }, { k: i[2], v: a[1] }, { k: i[3], v: a[1] }, { k: i[5], v: a[1] }, { k: i[7], v: a[1] }, { k: i[8], v: a[2] }, { k: i[9], v: a[0] }]; break;
      case 'Paid': stepper = [{ k: i[1], v: a[1] }, { k: i[2], v: a[1] }, { k: i[3], v: a[1] }, { k: i[5], v: a[1] }, { k: i[7], v: a[1] }, { k: i[8], v: a[1] }, { k: i[9], v: a[4] }]; break;
      case 'Rejected by Technical Rep.': stepper = [{ k: i[1], v: a[1] }, { k: i[2], v: a[1] }, { k: i[4], v: a[3] }]; break;
      case 'Not Found': stepper = [{ k: i[1], v: a[1] }, { k: i[2], v: a[1] }, { k: i[3], v: a[1] }, { k: i[5], v: a[1] }, { k: i[6], v: a[3] }]; break;
      case 'Rejected': stepper = [{ k: i[1], v: a[1] }, { k: i[2], v: a[1] }, { k: i[3], v: a[1] }, { k: i[5], v: a[1] }, { k: i[7], v: a[1] }, { k: i[10], v: a[3] }]; break;
      case 'Rejected by Admin':
        if (this.state.invoice.sentForFITechnicalRepReviewDate == null) // previously Draft
          stepper = [{ k: i[1], v: a[1] }, { k: i[11], v: a[3] }];
        else if (this.state.invoice.fITechnicalRepReviewedDate == null) // previously Awaiting Technical Rep. Review or Rejected by Technical Rep.
          stepper = [{ k: i[1], v: a[1] }, { k: i[2], v: a[1] }, { k: i[11], v: a[3] }];
        else // previously Accepted by Technical Rep.
          stepper = [{ k: i[1], v: a[1] }, { k: i[2], v: a[1] }, { k: i[3], v: a[1] }, { k: i[11], v: a[3] }];
        break;
      default: stepper = [];
    }

    this.setState({ Stepper: stepper });
  }

  GetFIInvoiceDocuments = () => {
    ajaxCall("Invoice/GetFIInvoiceDocuments/" + this.state.invoice.id).then(res => {
      closeAlert();
      this.setState({ FIUploadedDocuments: res.data.data }, this.GetFICategories);
    })
      .catch(error => { errorAction(error) })
  }

  GetFICategories = () => {
    ajaxCall("FICategory").then(res => {
      let record = res.data.data.filter(g => (g.id === Number(this.state.invoice.fiCategoryId)))[0].requiredDocumentsList;
      this.setState({ FIDocuments: record });

      //FIExtraDocuments lists
      let uploadedDocList = this.state.FIUploadedDocuments.filter(g => !(record.includes(g.documentTypeName)));
      let listFilter = uploadedDocList.map(item => { return item.documentTypeName; })

      if (uploadedDocList.length > 0)
        this.setState({ FIExtraDocuments: listFilter });
      else
        this.setState({ FIExtraDocuments: [] });


      closeAlert();
    })
      .catch(error => { errorAction(error) })
  }

  DownloadAllDocument = () => {
    Preloader();
    ajaxCall("Invoice/DownloadAllInvoiceFIDocuments/" + this.state.invoice.id, 'blob').then(res => {
      // let objecturl = URL.createObjectURL(res.data);
      // window.open(objecturl, '_blank').focus();

      this.setState({ InvoiceSupportingPDF: URL.createObjectURL(res.data) });

      // let win = window.open(objecturl, '_blank');

      // if(win) window.focus();
      // else{
      //     window.setTimeout( function(){ if(win) win.focus(); }, 100 );
      // }

      closeAlert();
    })
      .catch(error => { errorAction(error) })
  }

  UploadDocument = (i) => {
    /**  @type {HTMLInputElement} */
    let fileInput = document.querySelector("#documentFile" + i);
    let file = fileInput.files[0];

    /**  @type {HTMLInputElement} */
    let nameInput = document.querySelector("#documentName" + i);
    let name = nameInput.value;

    if (name === '') { errorAlert("Please enter the document name to continue"); return; }
    if (file === undefined) { errorAlert("Please choose a document to continue"); return; }

    let formData = new FormData();
    formData.append("Document", file);
    formData.append("DocumentTypeName", name);

    ajaxCall("Invoice/UploadInvoiceFIDocument/" + this.state.invoice.id, 'json', 'post', formData).then(res => {
      this.GetFIInvoiceDocuments();
      successAlert(name + ' document uploaded successfully')
    })
      .catch(error => { errorAction(error) })
  }

  ShowDocumentName = (o) => {
    if (this.state.FIUploadedDocuments.filter(g => (g.documentTypeName === o)).length > 0)
      return this.state.FIUploadedDocuments.filter(g => (g.documentTypeName === o))[0].documentTypeName
  }

  DownloadDocument = (filename) => {
    Preloader();
    ajaxCall("Invoice/DownloadInvoiceFIDocument/" + this.state.invoice.id + "/" + encodeURIComponent(filename), 'blob').then(res => {
      let objecturl = URL.createObjectURL(res.data);
      // window.open(objecturl, '_blank').focus();

      let win = window.open(objecturl, '_blank');

      if (win) window.focus();
      else {
        window.setTimeout(function () { if (win) win.focus(); }, 100);
      }

      closeAlert();
    })
      .catch(error => { errorAction(error); console.log(error) })
  }

  ReviewFIInvoice = (status) => {
    if (this.state.form.review === '') {
      alertPrompt("Comment is required"); return;
    }

    let frontenReviewURI = FRONTEND_URL + "/sign-fi-invoice/" + this.state.invoice.id;
    let data = {
      "Validated": status,
      "Comments": this.state.form.review,
      "ReviewedNotificationLink": frontenReviewURI,
      "RejectedNotificationLink": frontenReviewURI
    }
    Preloader();
    ajaxCall("Invoice/ReviewFIInvoice/" + this.state.invoice.id, 'json', 'patch', data).then(res => {
      tinyAlert("success", "Invoice review sent!");
      this.GetInvoiceByID();
    })
      .catch(error => { errorAction(error) })
  }

  ChangeUserDepartment = () => {
    console.log(this.state.form.fiDepartment)
    if (this.state.form.fiDepartment.value === '') {
      errorAlert("Kindly select your User's Department");
      return;
    }


    let data = {
      "fiDeptId": this.state.form.fiDepartment.value,
      "invoiceReviewLink": this.state.invoice.invoiceReviewLink,
      "adminViewLink": this.state.invoice.adminViewLink
    }

    Preloader();

    /** @type HTMLInputElement */
    // let refElement = document.querySelector("#submitTechRep");
    // let button_name = refElement.innerHTML;
    // refElement.disabled = true;
    // refElement.innerHTML = Spinner;

    ajaxCall("Invoice/ReSendFIInvoiceForReview/" + this.state.invoice.id, 'json', 'patch', data).then(res => {
      closeAlert();
      tinyAlert("success", "Your invoice was submitted for review successfully!");

      // refElement.innerHTML = "Documents subimitted for review";
      this.GetInvoiceByID();
    })
      .catch(error => {
        // refElement.disabled = false;
        // refElement.innerHTML = button_name;

        errorAction(error);
      })
  }

  GetFIDepartment = () => {
    ajaxCall("FIDept").then(res => {
      closeAlert();
      let record = res.data.data.filter(g => (g.active === true));
      let list = record.map((r) => ({ value: r.id, name: 'fiDepartment', label: r.dept }));

      let fidept = list.filter(r => (r.value === this.state.invoice.fiDeptId))

      this.setState(prevState => ({ form: { ...prevState.form, 'fiDepartment': fidept[0] } }))

      this.setState({ FIDepartment: list });
    })
      .catch(error => { errorAction(error) })
  }

  render() {
    return (
      <div className='mainApp'>
        <div className='container-fluid'>
          <div className='row'>
            <SideNav />

            <div className='main-content-wrap'>
              <TopNav />
              <PageTitle pagedata={{ page: 'Invoice', breadcrumb: 'Invoice ' + this.state.invoice.invoiceReferenceId }} />

              <div className='container mb-5'>
                <div className='row'>
                  <div className='col-12 mt-3'>
                    <ul className="stepper">
                      {this.state.Stepper.map((x, i) => { return <li className={`stepper__item ${x.v}`} key={i}>{x.k}</li> })}
                    </ul>
                  </div>

                  <div className='col-12 mb-5'>
                    <div className="">

                      <ul className="nav nav-tabs">
                        <li className="nav-item">
                          <button className="nav-link active font-14" aria-current="page" id="invoice-tab" data-bs-toggle="tab" data-bs-target="#invoice">Submitted Invoice</button>
                        </li>
                        {this.state.invoice.invoiceType === 'Non-PO' &&
                          <li className="nav-item">
                            <button className="nav-link font-14 text-black" id="support_document-tab" data-bs-toggle="tab" data-bs-target="#support_document">Support Documents</button>
                          </li>
                        }
                      </ul>

                      <div className="tab-content" id="myTabContent">

                        <iframe className="tab-pane fade show active" id="invoice" aria-labelledby="invoice-tab" src={DOMPurify.sanitize(this.state.InvoicePDF)} height="700" width="100%" title="Invoice" style={{ paddingBottom: '15px' }}></iframe>

                        <iframe className="tab-pane fade" id="support_document" aria-labelledby="support_document-tab" src={DOMPurify.sanitize(this.state.InvoiceSupportingPDF)} height="700" width="100%" title="Invoice" style={{ paddingBottom: '15px' }}></iframe>

                      </div>

                      {this.state.invoice.invoiceType === 'Non-PO' ?
                        <div className=''>
                          <form className="login-form" autoComplete="off" method="post">
                            <div className='row'>
                              <div>
                                <h6 className='mt-2'>Support Documents</h6>
                              </div>
                              <div className='col-sm-12'>
                                <table className="table font-12 table-striped table-hover mb-1 table-bordered">
                                  <thead className='text-white' style={{ background: '#0556de' }}>
                                    <tr>
                                      <th className='text-center' style={{ width: '50px' }}>SN</th>
                                      <th>Document Name</th>
                                      <th style={{ width: '30px' }}>View</th>
                                    </tr>
                                  </thead>
                                  <tbody className='font-12'>
                                    {
                                      this.state.FIDocuments.map((o, i) => {
                                        return (
                                          <tr key={i}>
                                            <td className="text-center"> {i + 1} </td>
                                            <td> {o} </td>
                                            <td className='d-flex justify-content-between'>
                                              {this.ShowDocumentName(o) !== undefined ?
                                                <button type='button' onClick={() => this.DownloadDocument(this.ShowDocumentName(o))} title='Download Document' className='btn btn-primary font-12 border-radius-sm'><i className='fa fa-download'></i> View Document</button> : ''
                                              }
                                            </td>
                                          </tr>
                                        )
                                      })
                                    }
                                    {
                                      this.state.FIExtraDocuments.map((o, i) => {
                                        return (
                                          <tr key={i}>
                                            <td className="text-center"> {i + 1 + this.state.FIDocuments.length} </td>
                                            <td> {o} </td>
                                            <td className='d-flex justify-content-between'>
                                              {
                                                this.ShowDocumentName(o) !== undefined ?
                                                  <button type='button' onClick={() => this.DownloadDocument(this.ShowDocumentName(o))} title='Download Document' className='btn btn-primary font-12 border-radius-sm'><i className='fa fa-download'></i> View Document</button> : ''
                                              }
                                            </td>
                                          </tr>
                                        )
                                      })
                                    }
                                  </tbody>
                                </table>
                              </div>

                              <div className='col-sm-10'></div>
                              <div className='col-sm-2 text-end'>
                                {/* <button type='button' onClick={() => this.DownloadAllDocument()} title='Download Document' className='btn btn-primary font-12 border-radius-sm bold'>View All Support Documents</button> */}
                              </div>
                            </div>

                            {
                              this.state.invoice.status === 'Awaiting Technical Rep. Review' ?
                                <div>
                                  <div className='row mt-3 sm-mt-1'>
                                    <div className='col-sm-12 col-12'>
                                      <div className="form-group mb-0">
                                        <div className='info-alert mb-1 text-danger bold' style={{ borderLeft: '3px solid red' }}>Please endeavor to confirm the correct "User's Department"</div>
                                        <Select name='fiDepartment'
                                          value={this.state.form.fiDepartment}
                                          onChange={this.handleFormSelectChange}
                                          isSearchable={true}
                                          placeholder="Select User's Department"
                                          options={this.state.FIDepartment}
                                        />
                                      </div>
                                    </div>
                                    <div className='col-sm-4 mt-2'>
                                      <div className="form-group mt-0 mb-5">
                                        <button type='button' onClick={() => confirmPrompt("Do you want to update user's department?").then((r) => r.isConfirmed ? this.ChangeUserDepartment() : false)} name='sendReview' className='btn btn-primary font-12 border-radius-sm form-control'>Change User Department</button>
                                      </div>
                                    </div>
                                  </div>


                                  <p className='mt-3 mb-0'>Comment</p>
                                  <hr className='mb-3 mt-1' />
                                  <div className='row mt-3'>
                                    <div className='col-12'>
                                      <textarea placeholder='Technical representative comment' name='review' onChange={this.handleFormInputsChange} className="form-control font-12 border-radius-sm" style={{ height: '150px' }}></textarea>
                                    </div>
                                  </div>

                                  <div className="row mt-1">
                                    <div className='col-sm-8'></div>
                                    <div className='col-sm-2'>
                                      <div className="form-group mt-0 mb-5">
                                        <button type='button' onClick={() => confirmPrompt("Do you want to approve").then((r) => r.isConfirmed ? this.ReviewFIInvoice(true) : false)} name='sendReview' className='btn btn-primary font-12 border-radius-sm form-control'>Accept</button>
                                      </div>
                                    </div>
                                    <div className='col-sm-2'>
                                      <div className="form-group mt-0 mb-5">
                                        <button type='button' onClick={() => confirmPrompt("Do you want to reject?").then((r) => r.isConfirmed ? this.ReviewFIInvoice(false) : false)} name='sendReview' className='btn btn-danger font-12 border-radius-sm form-control'>Reject</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                :
                                <></>
                            }
                          </form>
                        </div>
                        :
                        <></>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


export default withRouter(ViewInvoice);