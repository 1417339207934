import React, { Component } from 'react';


import SideNav from '../layouts/SideNav';
import TopNav from '../layouts/TopNav';
import PageTitle  from '../layouts/PageTitle';

import { Preloader, ajaxCall, closeAlert, errorAction } from '../helpers/Helpers'


const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const id = urlParams.get('id');

class TepngUserInfo extends Component {
  componentDidMount() {
    this.GetTepngUser();
  }

  state = {
    user:{}
  }

  GetTepngUser = () => {
    Preloader();
    let self = this;
        
    ajaxCall("TepngUser/"+id).then(res => {
      closeAlert();
      self.setState({ user: res.data.data });
    })
    .catch(error => {
      errorAction(error)
    })
  }

  render() {
    return (
      
      <div className='mainApp'>
      <div className='container-fluid'>
        <div className='row'>
          <SideNav />
          
          <div className='main-content-wrap'>
            <TopNav />
            <PageTitle pagedata={{page:'Tepng User',breadcrumb:'Tepng User Information'}}/>

            <div className='container mt-1 mb-5'>
              <div className='row'>

                <div className='col-12 px-4'>
                  <div className='mt-4 mb-4'>
                    <div className='table-responsive'>
                      <table className="table font-12 table-striped table-hover mb-1 table-bordered">
                        <tbody className='font-12'>
                          <tr>
                              <td style={{width:'200px'}}>                                        
                              firstName
                              </td>
                              <td>
                                {this.state.user.firstName}
                              </td>
                          </tr>
                          <tr>
                              <td>                                        
                              lastName
                              </td>
                              <td>
                                {this.state.user.lastName}
                              </td>
                          </tr>
                          <tr>
                              <td>                                        
                              Email
                              </td>
                              <td>
                                {this.state.user.email}
                              </td>
                          </tr>
                          <tr>
                              <td>                                        
                              isAdmin
                              </td>
                              <td>
                                {this.state.user.isAdmin ? 'Yes' : 'No'}
                              </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

export default TepngUserInfo;