import React, { Component } from 'react';
import { Link } from 'react-router-dom';




class PageTitle extends Component {
  render() {
    return (
      <div className='px-4 mt-1 py-4 page-lead-wrap'>
        <div className='d-flex justify-content-between'>
          <div className="mt-2 bold">{this.props.pagedata.page}</div>
          <nav aria-label="breadcrumb font-12 mb-0">
            <ol className="breadcrumb mb-0 mt-2">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item active" aria-current="page">{this.props.pagedata.breadcrumb}</li>
            </ol>
          </nav>
        </div>
      </div>
    );
  }
}

export default PageTitle;