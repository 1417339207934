import React, { Component } from "react";
import { AUTH_URL, closeAlert, errorAction, LoginAuth, Preloader } from '../../helpers/Helpers';


import withRouter from '../../../src/WithRouter';

class LoginTepngUser extends Component {
  state = {}

  componentDidMount() {
    this.loginTepngUser()
  }

  loginTepngUser = () => {   
    Preloader();

    LoginAuth('AuthenticateTepngUser/Login', {}).then(res => { 
      const loginRedirectedFromUrl = localStorage.getItem('loginRedirectedFromUrl');
      localStorage.removeItem('loginRedirectedFromUrl');
      this.props.routerNavigate(loginRedirectedFromUrl ?? "/");

      closeAlert();
    })
    .catch(error => {
      let queryParams = new URLSearchParams(window.location.search);
      if (queryParams.get("azureauthredirect") !== '1' && error.response.status === 401) {
        let url = new URL(window.location.origin + '/login-tepnguser')
        url.searchParams.append('azureauthredirect', '1')
        window.location.replace(AUTH_URL + '/.auth/login/aad?post_login_redirect_uri=' + window.encodeURIComponent(url.href));
      }
      else
        errorAction(error);
    })
  }

  render() {
    return <></>
  }

}


export default withRouter(LoginTepngUser);
