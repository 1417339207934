import React, { Component } from 'react';
import { Link } from 'react-router-dom';


import SideNav from '../layouts/SideNav';
import TopNav from '../layouts/TopNav';
import PageTitle  from '../layouts/PageTitle';
import PrevIcon from '../layouts/PrevIcon';
import NextIcon from '../layouts/NextIcon';

import { Preloader, ajaxCall, closeAlert, errorAction, tinyAlert } from '../helpers/Helpers'


class Vendors extends Component {
  componentDidMount() {
    this.GetVendor();
  }

  state = {
    allVendors:[],
    filteredVendors:[],
    currentPageVendors:[],

    numRecordsPerPage: 25,
    currentPage: 1,
    numOfPages: 1,
  }

  GetVendor = () => {
    Preloader();
    let self = this;   

    ajaxCall('Vendor').then(res => {
      closeAlert();
      self.setState({ allVendors: res.data.data }, this.filteredVendorsTable);
    })
    .catch(error => {
      errorAction(error)
    })
  }

  filteredVendorsTable = (e) => {
    let result
    if (e != null) {
      let search = e.target.value.replaceAll(' ','').toLowerCase();
      result = this.state.allVendors.filter((x) => x.address.replaceAll(' ','').toLowerCase().includes(search) || x.name.replaceAll(' ','').toLowerCase().includes(search) || x.phone.replaceAll(' ','').toLowerCase().includes(search) || x.vendorCode.replaceAll(' ','').toLowerCase().includes(search)|| x.tinNo.replaceAll(' ','').toLowerCase().includes(search));
    }
    else
      result = this.state.allVendors;

    this.setState({ filteredVendors: result.map((r, i) => ({ ...r, index: i + 1 })) }, this.updateTablePagination);
  }

  updateTablePagination = () => {
    let recordCount = this.state.filteredVendors.length
    let numOfPages = Math.ceil(recordCount / this.state.numRecordsPerPage)
    let currentPage = this.state.currentPage
    if (currentPage < 1) currentPage = 1;
    if (currentPage > numOfPages) currentPage = numOfPages;
    this.setState({ currentPage: currentPage, numOfPages: numOfPages }, this.setRecordsForCurrentPage)
  }

  setRecordsForCurrentPage = () => {
    const offset = (this.state.currentPage - 1) * this.state.numRecordsPerPage;
    this.setState({ currentPageVendors: this.state.filteredVendors.slice(offset, offset + this.state.numRecordsPerPage) });
  }

  setNumRecordsPerPage = (e) => {
    this.setState({ numRecordsPerPage: Number(e.target.value) }, this.updateTablePagination);
  }

  nextPage = (e) => {
    this.setState({ currentPage: Number(this.state.currentPage) + 1 }, this.setRecordsForCurrentPage);
  }

  previousPage = (e) => {
    this.setState({ currentPage: Number(this.state.currentPage) - 1 }, this.setRecordsForCurrentPage);
  }

  editRecord = (id, status) => {
    return this.state.allVendors.map(item => {
        var temp = Object.assign({}, item);
        if (temp.vendorCode === id) {
            temp.partInvoicingEnabled = status;
        }
        return temp;
    });
  }

  EnablePartInvoicing = (id) => {
    Preloader();
    
    ajaxCall("Vendor/EnablePartInvoicing/"+id, 'json', 'patch').then(res => {
      tinyAlert('success',res.data.message); closeAlert();
      let new_record = this.editRecord(id,true);
      this.setState({allVendors: new_record}, this.filteredVendorsTable);
    })
    .catch(error => {
      errorAction(error);
    })
  }

  DisablePartInvoicing = (id) => {
    Preloader();
    
    ajaxCall("Vendor/DisablePartInvoicing/"+id, 'json', 'patch').then(res => {
      tinyAlert('success',res.data.message); closeAlert();
      let new_record = this.editRecord(id,false);
      this.setState({allVendors: new_record}, this.filteredVendorsTable);
    })
    .catch(error => {
      errorAction(error);
    })
  }

  
  render() {
    return (
      
      <div className='mainApp'>
      <div className='container-fluid'>
        <div className='row'>
          <SideNav />
          
          <div className='main-content-wrap'>
            <TopNav />
            <PageTitle pagedata={{page:'Vendors',breadcrumb:'Registered Vendors'}}/>

            <div className='container mt-1 mb-5'>
              <div className='row'>
                <div className='col-12 px-4'>
                  <div className='mt-4 mb-4'>

                    <div className='mb-3 mt-1' style={{position:'relative'}}>
                      <input type="text" className="form-control input-font bold" placeholder='Search...' onInput={this.filteredVendorsTable}/>
                      <i className='fa fa-search' style={{position:'absolute', right:'20px', top:'12px', color:'#939597'}}></i>
                    </div>

                    <div className='table-responsive'>
                      <table className="table font-12 table-striped table-hover mb-1 table-bordered">
                        <thead className='text-white'>
                          <tr>
                            <th className='text-center'>SN</th>
                            <th>Name</th>
                            <th>Phone</th>
                            <th>Vendor</th>
                            <th>TIN No</th>
                            <th>Address</th>
                            <th className='d-none'>invoice Theme</th>
                            <th style={{width:'75px'}} className='text-center'> Status</th>
                            <th style={{width:'110px'}} className='text-center'>Part Invoicing</th>
                            <th className='text-center'> View</th>
                          </tr>
                        </thead>
                        <tbody className='font-12'>
                        {
                          this.state.currentPageVendors.map(object => {
                            return (
                            <tr key={object.index}>
                              <td className='text-center'>{object.index}</td>
                              <td>{object.name}</td>
                              <td>{object.phone}</td>
                              <td>{object.vendorCode}</td>
                              <td>{object.tinNo}</td>
                              <td>{object.address}</td>
                              <td className='text-center'>{ object.active ?  'Yes' :  'No' }</td>
                              <td className='text-center'>
                                { 
                                  object.partInvoicingEnabled ? 
                                  <button disabled={object.status === 'Submitted'} className='btn btn-sm btn-danger bold border-radius-sm' onClick={() => this.DisablePartInvoicing(object.vendorCode)}>Disable</button> 
                                  : 
                                  <button disabled={object.status === 'Submitted'} className='btn btn-sm btn-success bold border-radius-sm' onClick={() => this.EnablePartInvoicing(object.vendorCode)}>Enable</button>
                                }
                              </td>
                              <td className='text-center'>
                                <Link to={`/vendor-info/${object.vendorCode}`} className="btn btn-sm btn-info bold border-radius-sm checkBtn">View</Link>                                        
                              </td>
                            </tr>
                            )
                          })
                        } 
                        </tbody>
                      </table>
                    </div>
                    
                    { this.state.allVendors.length === 0 ? <p className='font-12 text-center p-3'>Ops! No record available...</p>: '' }


                    <div className="row mb-2 mt-2">
                      <div className='col-8'></div>
                      <div className='col-1 text-end'>
                        <label className='font-12'>Per Page:</label>
                      </div>
                      <div className='col-1'>
                        <select className='font-12 form-select border-radius-sm' value={this.state.numRecordsPerPage} onChange={this.setNumRecordsPerPage}>
                          <option value='3'>3</option>
                          <option value='5'>5</option>
                          <option value='10'>10</option>
                          <option value='25'>25</option>
                          <option value='100'>100</option>
                          <option value='250'>250</option>
                        </select>
                      </div>
                      <div className='col-1 p-0'>
                        <button className='btn bg-light font-12 border-radius-sm' disabled={this.state.currentPage === 1} onClick={this.previousPage}><PrevIcon /> Prev</button>
                      </div>
                      <div className='col-1 p-0'>
                        <button className='btn bg-light font-12 border-radius-sm' disabled={this.state.currentPage === this.state.numOfPages} onClick={this.nextPage}>Next <NextIcon /></button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    );
  }
}

export default Vendors;