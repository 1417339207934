import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import ReactExport from "react-export-excel";
import { ExcelFile, ExcelSheet, ExcelColumn } from 'react-xlsx-wrapper';


import SideNav from '../layouts/SideNav';
import TopNav from '../layouts/TopNav';
import PageTitle from '../layouts/PageTitle';
import PrevIcon from '../layouts/PrevIcon';
import NextIcon from '../layouts/NextIcon';


import { Preloader, ajaxCall, closeAlert, errorAction, formatAmount, formatDate, confirmPrompt, tinyAlert } from '../helpers/Helpers'


class Invoice extends Component {
  componentDidMount() {
    this.GetInvoices();
  }

  state = {
    allInvoice: [],
    filteredInvoice: [],
    currentPageInvoice: [],

    numRecordsPerPage: 25,
    currentPage: 1,
    numOfPages: 1,
    filter_start_month: '',
    filter_end_month: ''
  }

  GetInvoices = () => {
    Preloader();
    let self = this;

    ajaxCall('Invoice').then(res => {
      closeAlert();
      self.setState({ allInvoice: res.data.data }, this.filteredInvoiceTable);
      // self.setState({ allInvoice: res.data.data }, this.filteredInvoiceTableStatus);
    })
      .catch(error => {
        errorAction(error);
      })
  }

  filteredInvoiceTable = (e) => {
    let result = [];
    if (e != null) {
      let search = e.target.value.toLowerCase();
      if (e.target.name === 'invoice_status') {
        result = this.state.allInvoice.filter((x) => x.status.toLowerCase() === search);
      }
      else if (e.target.name === 'invoice_type') {
        result = this.state.allInvoice.filter((x) => x.invoiceType.toLowerCase() === search);
      }
      else if (e.target.name === 'create_start_month') {
        this.setState({ filter_start_month: e.target.value })

        if (this.state.filter_end_month === '') {
          tinyAlert('info', 'Select end month')
          return;
        }

        let filter_start_month = new Date(e.target.value);


        let Year = new Date(this.state.filter_end_month).getFullYear();
        let Month = new Date(this.state.filter_end_month).getMonth();
        let lastDateOfEndMonth = new Date(Year, Month + 1, 0);

        let startDate = filter_start_month.getTime()
        let endDate = lastDateOfEndMonth.getTime()

        console.log(startDate, endDate)
        result = this.state.allInvoice.filter((value) => new Date(value.createdDate).getTime() >= startDate && new Date(value.createdDate).getTime() <= endDate);

        console.log(result)
      }
      else if (e.target.name === 'create_end_month') {
        this.setState({ filter_end_month: e.target.value })

        if (this.state.filter_start_month === '') {
          tinyAlert('info', 'Select start month')
          return;
        }

        let filter_start_month = new Date(this.state.filter_start_month);


        let Year = new Date(e.target.value).getFullYear();
        let Month = new Date(e.target.value).getMonth();
        let lastDateOfEndMonth = new Date(Year, Month + 1, 0);

        let startDate = filter_start_month.getTime()
        let endDate = lastDateOfEndMonth.getTime()

        console.log(startDate, endDate)
        result = this.state.allInvoice.filter((value) => new Date(value.createdDate).getTime() >= startDate && new Date(value.createdDate).getTime() <= endDate);

        console.log(result)

      }
      else {
        result = this.state.allInvoice.filter((x) => x.id.toString().includes(search) || x.bankAccountNumber.includes(search) || x.invoiceReferenceId.toLowerCase().includes(search) || x.bankAccountName.toLowerCase().includes(search) || x.vendorCode.includes(search) || x.status.toLowerCase().includes(search) || x.invoiceType.toLowerCase().includes(search));
      }
    }
    else {
      result = this.state.allInvoice;
    }

    this.setState({ filteredInvoice: result.map((r, i) => ({ ...r, index: i + 1 })) }, this.updateTablePagination);
  }

  updateTablePagination = () => {
    let recordCount = this.state.filteredInvoice.length
    let numOfPages = Math.ceil(recordCount / this.state.numRecordsPerPage)
    let currentPage = this.state.currentPage
    if (currentPage < 1) currentPage = 1;
    if (currentPage > numOfPages) currentPage = numOfPages;
    this.setState({ currentPage: currentPage, numOfPages: numOfPages }, this.setRecordsForCurrentPage)
  }

  setRecordsForCurrentPage = () => {
    const offset = (this.state.currentPage - 1) * this.state.numRecordsPerPage;
    this.setState({ currentPageInvoice: this.state.filteredInvoice.slice(offset, offset + this.state.numRecordsPerPage) });
  }

  setNumRecordsPerPage = (e) => {
    this.setState({ numRecordsPerPage: Number(e.target.value) }, this.updateTablePagination);
  }

  nextPage = (e) => {
    this.setState({ currentPage: Number(this.state.currentPage) + 1 }, this.setRecordsForCurrentPage);
  }

  previousPage = (e) => {
    this.setState({ currentPage: Number(this.state.currentPage) - 1 }, this.setRecordsForCurrentPage);
  }

  ResubmitInvoice = (id) => {
    Preloader();

    ajaxCall("Invoice/ReSubmitInvoice/" + id, 'json', 'patch').then(res => {
      closeAlert();
      tinyAlert('success', 'Invoice resubmitted successfully.');
      let new_record = this.editRecord(id, 'Submitted');
      this.setState({ allInvoice: new_record }, this.filteredInvoiceTable);
    })
      .catch(error => {
        errorAction(error);
      })
  }

  RejectInvoice = (id) => {
    Preloader();

    ajaxCall("Invoice/RejectInvoice/" + id, 'json', 'patch').then(res => {
      closeAlert();
      tinyAlert('success', 'Invoice rejected by Admin successfully.');
      let new_record = this.editRecord(id, 'Rejected by Admin');
      this.setState({ allInvoice: new_record }, this.filteredInvoiceTable);
    })
      .catch(error => {
        errorAction(error);
      })
  }


  UndotoDraft = (id) => {
    Preloader();

    ajaxCall("Invoice/UndoInvoiceSubmission/" + id, 'json', 'patch').then(res => {
      closeAlert();
      tinyAlert('success', 'Invoice undo to draft successfully.');
      let new_record = this.editRecord(id, 'Draft');
      this.setState({ allInvoice: new_record }, this.filteredInvoiceTable);
    })
      .catch(error => {
        errorAction(error);
      })
  }

  updateCockpitNo = (id, oldCockpitId) => {
    let cockpitNumber = prompt("Enter New Cockpit Number", oldCockpitId ?? "")
    if (cockpitNumber == null || cockpitNumber.trim() === "") return;
    Preloader();

    ajaxCall(`Invoice/UpdateCockpitNumber/${id}/${cockpitNumber}`, 'json', 'patch').then(res => {
      closeAlert();
      tinyAlert('success', 'Cockpit Number updated successfully.');
      let new_record = this.editCockpitNumber(id, cockpitNumber);
      this.setState({ allInvoice: new_record }, this.filteredInvoiceTable);
    })
      .catch(error => {
        errorAction(error);
      })
  }

  editRecord = (id, status) => {
    return this.state.allInvoice.map(item => {
      var temp = Object.assign({}, item);
      if (temp.id === id) {
        temp.status = status;
      }
      return temp;
    });
  }

  editCockpitNumber = (id, cockpitNumber) => {
    return this.state.allInvoice.map(item => {
      var temp = Object.assign({}, item);
      if (temp.id === id) {
        temp.cockpitId = cockpitNumber;
      }
      return temp;
    });
  }

  render() {
    return (
      <div className='mainApp'>
        <div className='container-fluid'>
          <div className='row'>
            <SideNav />

            <div className='main-content-wrap'>
              <TopNav />
              <PageTitle pagedata={{ page: 'Invoices', breadcrumb: 'Submitted Invoice' }} />

              <div className='row'>
                <div className='col-8 bold'>
                  {this.state.filteredInvoice.length} of {this.state.allInvoice.length} Records Available
                  {/* of {this.state.allInvoice.length}  */}
                </div>

                <div className='row'>
                  <div className='col-8 bold'>
                    {this.state.allInvoice.length} Records Available
                  </div>

                  <div className='col-4 text-end'>
                    <ExcelFile filename="VIMP-Invoice" element={<button className='btn btn-primary font-12 bold rounded-0 shadow-sm'>
                      <svg xmlns="http://www.w3.org/2000/svg" style={{ position: 'relative', top: '-2px' }} fill='#fff' height="1em" viewBox="0 0 384 512"><path d="M48 448V64c0-8.8 7.2-16 16-16H224v80c0 17.7 14.3 32 32 32h80V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0H64zm90.9 233.3c-8.1-10.5-23.2-12.3-33.7-4.2s-12.3 23.2-4.2 33.7L161.6 320l-44.5 57.3c-8.1 10.5-6.3 25.5 4.2 33.7s25.5 6.3 33.7-4.2L192 359.1l37.1 47.6c8.1 10.5 23.2 12.3 33.7 4.2s12.3-23.2 4.2-33.7L222.4 320l44.5-57.3c8.1-10.5 6.3-25.5-4.2-33.7s-25.5-6.3-33.7 4.2L192 280.9l-37.1-47.6z" /></svg>
                      &nbsp;&nbsp;

                      Export Record</button>}>
                      <ExcelSheet data={this.state.filteredInvoice} name="Invoice">
                        <ExcelColumn label="Invoice Reference ID" value="invoiceReferenceId" />
                        <ExcelColumn label="Vendor Code" value="vendorCode" />
                        <ExcelColumn label="Bank Account Name" value="bankAccountName" />
                        <ExcelColumn label="Bank Account Number" value={'bankAccountNumber'} />
                        <ExcelColumn label="Invoice Currency" value={'invoiceCurrency'} />
                        <ExcelColumn label="Amount" value={'grossAmount'} />
                        <ExcelColumn label="Invoice Type" value="invoiceType" />
                        <ExcelColumn label="status" value="status" />
                        <ExcelColumn label="Created Date" value="createdDate" />
                        <ExcelColumn label="Submitted Date" value="submittedDate" />
                        <ExcelColumn label="admin Notified On Not Found" value="adminNotifiedOnNotFound" />
                        <ExcelColumn label="admin Notified On Rejection" value="adminNotifiedOnRejection" />
                        <ExcelColumn label="amount VAT Taxable" value="amountVATTaxable" />
                        <ExcelColumn label="bank Currency" value="bankCurrency" />
                        <ExcelColumn label="bank Name" value="bankName" />
                        <ExcelColumn label="bank Sort Code" value="bankSortCode" />
                        <ExcelColumn label="bank Swift Code" value="bankSwiftCode" />
                        <ExcelColumn label="clearing Date" value="clearingDate" />
                        <ExcelColumn label="cockpit Id" value="cockpitId" />
                        <ExcelColumn label="created By" value="createdBy" />
                        <ExcelColumn label="due Date" value="dueDate" />
                        <ExcelColumn label="fi Technical RepReview Comments" value="fiTechnicalRepReviewComments" />
                        <ExcelColumn label="fi Technical Rep Reviewed Date" value="fiTechnicalRepReviewedDate" />
                        <ExcelColumn label="fi Technical Rep Reviewer" value="fiTechnicalRepReviewer" />
                        <ExcelColumn label="id" value="id" />
                        <ExcelColumn label="net Amount" value="netAmount" />
                        <ExcelColumn label="po No" value="poNo" />
                        <ExcelColumn label="sent For FI Technical Rep Review By" value="sentForFITechnicalRepReviewBy" />
                        <ExcelColumn label="sent For FI Technical Rep Review Date" value="sentForFITechnicalRepReviewDate" />
                        <ExcelColumn label="submitted Pdf Downloaded By Sap Integrator" value="submittedPdfDownloadedBySapIntegrator" />
                        <ExcelColumn label="submitted Pdf Downloaded By Sap Integrator Date" value="submittedPdfDownloadedBySapIntegratorDate" />
                        <ExcelColumn label="subsidiary Id" value="subsidiaryId" />
                        <ExcelColumn label="tax" value="tax" />
                        <ExcelColumn label="submitted By" value="submittedBy" />
                        <ExcelColumn label="tin No" value="tinNo" />
                        <ExcelColumn label="vat Rate" value="vatRate" />
                      </ExcelSheet>
                    </ExcelFile>
                  </div>
                </div>

                <div className='col-12'>
                  <div className='d-flex mt-1 mb-3 shadow-sm bg-white px-2 py-2' style={{ border: '1px solid #ced4da' }}>
                    <div className='col-4 col-lg-5 col-xl-6' style={{ position: 'relative', marginRight: '10px', borderRight: '1px dotted #c7c7c7', paddingRight: '10px' }}>
                      <input type="text" className="form-control input-font" placeholder='Find by reference no or Vendor no.' onInput={this.filteredInvoiceTable} />
                      <i className='fa fa-search' style={{ position: 'absolute', right: '20px', top: '12px', color: '#939597' }}></i>
                    </div>

                    <select name='invoice_type' className='form-control input-font bold' onChange={this.filteredInvoiceTable} style={{ height: '40px', marginRight: '5px' }}>
                      {/* <option value="" disabled>filter by status</option> */}
                      <option value="">All Type</option>
                      <option value="Material PO">Material PO</option>
                      <option value="Service PO">Service PO</option>
                      <option value="NON-PO">NON-PO</option>
                    </select>

                    <select name='invoice_status' className='form-control input-font bold' onChange={this.filteredInvoiceTable} style={{ height: '40px', marginRight: '20px' }}>
                      {/* <option value="" disabled>filter by status</option> */}
                      <option value="">All Status</option>
                      <option value="Draft">Draft</option>
                      <option value="Awaiting Technical Rep. Review">Awaiting Technical Rep. Review</option>
                      <option value="Rejected by Technical Rep.">Rejected by Technical Rep.</option>
                      <option value="Accepted by Technical Rep.">Accepted by Technical Rep.</option>
                      <option value="Submitted">Submitted</option>
                      <option value="Undergoing Processing">Undergoing Processing</option>
                      <option value="Awaiting Payment">Awaiting Payment</option>
                      <option value="Rejected">Rejected</option>
                      <option value="Rejected by Admin">Rejected by Admin</option>
                      <option value="Not Found">Not Found</option>
                      <option value="Paid">Paid</option>
                    </select>

                    <input type="month" name='create_start_month' onChange={this.filteredInvoiceTable} className='form-control input-font bold' style={{ height: '40px', borderRight: 'none', boxShadow: 'none' }} placeholder="Start month/year" />
                    <input type="month" name='create_end_month' onChange={this.filteredInvoiceTable} className='form-control input-font bold' style={{ height: '40px', borderLeft: 'none', boxShadow: 'none' }} placeholder="End month/year" />

                  </div>
                </div>

                <div className='col-12'>
                  <div className='mb-2 bg-white'>
                    <div className='table-responsive'>
                      <table className="table font-12 table-striped table-hover mb-0 table-bordered">
                        <thead className='text-white'>
                          <tr>
                            <th className='text-center' style={{ width: '10px' }}>#</th>
                            <th className='text-center' style={{ width: '180px' }}>Actions</th>
                            <th>ID</th>
                            <th>Cockpit Id</th>
                            <th style={{ width: '130px' }}>Reference No.</th>
                            <th style={{ width: '65px' }}>Vendor</th>
                            <th>Vendor Name</th>
                            <th style={{ width: '130px' }}>Account No.</th>
                            <th style={{ width: '130px' }} className='text-end'>Amount</th>
                            <th style={{ width: '89px' }}>Type</th>
                            <th style={{ width: '' }}>Status</th>
                            <th style={{ width: '130px' }}>Created Date</th>
                            {/* <th style={{width:'90px'}}>Time</th> */}
                            <th style={{ width: '130px' }}>Submitted Date</th>
                            {/* <th style={{width:'90px'}}>Time</th> */}
                          </tr>
                        </thead>
                        <tbody className='font-12'>
                          {
                            this.state.currentPageInvoice.map(object => {
                              return (
                                <tr key={object.index}>
                                  <td className='text-center'>{object.index}</td>
                                  <td className='text-center'>
                                    <Link to={'/view-invoice/' + object.id} title='View' className='btn btn-sm btn-info bold border-radius-sm checkBtn form-control mb-1' style={{ marginRight: '2px' }}>
                                      view
                                    </Link>

                                    <button disabled={object.status !== 'Submitted' && object.status !== 'Not Found'} title='Re-Submit' className='form-control mb-1 btn btn-sm btn-primary bold border-radius-sm checkBt' style={{ marginRight: '2px' }} onClick={() => confirmPrompt("Do you want to resubmit invoice" + object.invoiceReferenceId + "?").then((r) => r.isConfirmed ? this.ResubmitInvoice(object.id) : false)}>
                                      re-submit
                                    </button>

                                    <button disabled={object.status !== 'Not Found' && object.status !== 'Awaiting Technical Rep. Review'} title='Reject' className='form-control mb-1 btn btn-sm btn-danger bold border-radius-sm checkBt' style={{ marginRight: '2px' }} onClick={() => confirmPrompt("Do you want to reject invoice" + object.invoiceReferenceId + "?").then((r) => r.isConfirmed ? this.RejectInvoice(object.id) : false)}>
                                      reject
                                    </button>

                                    <button disabled={object.status !== 'Submitted'} title='Undo to draft' className='form-control mb-1 btn btn-sm btn-primary bold border-radius-sm checkBt' style={{ marginRight: '2px' }} onClick={() => confirmPrompt("Kindly confirm that this invoice is yet to go to cockpit and has been deleted from veryfi" + object.invoiceReferenceId + "?").then((r) => r.isConfirmed ? this.UndotoDraft(object.id) : false)}>
                                      undo to draft
                                    </button>
                                    <button disabled={object.status === 'Draft'} title='Edit Cockpit Number' className='form-control mb-1 btn btn-sm btn-primary bold border-radius-sm checkBt' style={{ marginRight: '2px' }} onClick={() => this.updateCockpitNo(object.id, object.cockpitId)}>
                                      Edit Cockpit Number
                                    </button>
                                  </td>
                                  <td>{object.id}</td>
                                  <td>{object.cockpitId}</td>
                                  <td>{object.invoiceReferenceId}</td>
                                  <td>{object.vendorCode}</td>
                                  <td>{object.bankAccountName}</td>
                                  <td>{object.bankAccountNumber}</td>
                                  <td className='text-end'><span>{object.bankCurrency}</span> {formatAmount(object.grossAmount)}</td>
                                  <td>{object.invoiceType}</td>
                                  <td title={object.status} className="truncate" style={{ width: '100px', overflow: 'hidden' }}>{object.status}</td>
                                  <td>{formatDate(object.createdDate, '')}</td>
                                  {/* <td>{formatDate(object.createdDate, 'time')}</td> */}
                                  <td>{formatDate(object.submittedDate, '')}</td>
                                  {/* <td>{formatDate(object.submittedDate, 'time')}</td> */}
                                </tr>
                              )
                            })
                          }

                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                {this.state.allInvoice.length === 0 ? <p className='font-12 text-center p-3'>Ops! No record available...</p> : ''}

                <div className="row mb-2 mt-2">
                  <div className='col-9'></div>
                  <div className='col-1'>
                    <select className='font-12 form-select border-radius-sm' value={this.state.numRecordsPerPage} onChange={this.setNumRecordsPerPage}>
                      <option value='3'>3</option>
                      <option value='5'>5</option>
                      <option value='10'>10</option>
                      <option value='25'>25</option>
                      <option value='100'>100</option>
                      <option value='250'>250</option>
                    </select>
                  </div>
                  <div className='col-1'>
                    <button className='btn bg-light font-12 border-radius-sm form-control' disabled={this.state.currentPage === 1} onClick={this.previousPage}><PrevIcon /> Prev</button>
                  </div>
                  <div className='col-1'>
                    <button className='btn bg-light font-12 border-radius-sm form-control' disabled={this.state.currentPage === this.state.numOfPages} onClick={this.nextPage}>Next <NextIcon /></button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Invoice;