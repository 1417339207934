import React, { Component } from "react";
import Logo from '../../assets/img/logo.png';
import { ConsentToCookies, closeAlert, confirmPrompt, errorAction, Preloader } from '../../helpers/Helpers';


import withRouter from '../../WithRouter';

class ConsentToPersonalDataAndCookies extends Component {
  state = {}

  ConsentToPersonalDataAndCookies = () => {
    Preloader();

    ConsentToCookies('TepngUser/ConsentToPersonalDataAndCookies', {}).then(res => {
      closeAlert();

      const loginRedirectedFromUrl = localStorage.getItem('loginRedirectedFromUrl');
      localStorage.removeItem('loginRedirectedFromUrl');
      this.props.routerNavigate(loginRedirectedFromUrl ?? "/");

    })
    .catch(error => {
      errorAction(error);
    })
  }

  render() {
    return (
      <div className='mainApp'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 mt-3'>
              <img style={{width:'100px'}} src={Logo} alt='logo' />
            </div>
          </div>

          <div className='row mt-3'>
            <div className="col-12">
              <h6 className='py-3 px-4 mb-0 lead-txt'>
                Personal Data and Cookies Consent
              </h6>
              <hr className='m-0' />
            </div>
          </div>

          <div className="col-12 font-14" style={{height: '70vh', background: '#fff', paddingTop: '25px'}}>
            <div className="bg-white px-4" style={{height: '60vh', overflowY: 'scroll'}}>
              <p className="mt-3 bold">Personal Data and Cookies Charter</p>
              <p>
                <span>Welcome to the Website </span>
                <a href="https://vimpadmin.tepnglocal.iasp.tgscloud.net/" target="_blank" rel="noreferrer noopener">
                  https://vimpadmin.tepnglocal.iasp.tgscloud.net</a>
              </p>

              <p>
                The Personal Data and Cookies Charter (hereinafter Charter) aims to inform you
                 about your rights that you can exercise regarding the processing of your data and describes
                 the measures that we take to protect these data.
              </p>

              <p>
                TotalEnergies EP Nigeria is the data controller with responsibility for personal data processing on the website 
                <a href="https://vimpadmin.tepnglocal.iasp.tgscloud.net/" target="_blank" rel="noreferrer noopener"> 
                   https://vimpadmin.tepnglocal.iasp.tgscloud.net 
                </a>. 
                These processing operations are carried out in accordance with the applicable law.
              </p>

              <p className="bold mb-0">1. Purpose of processing, legal basis, the period of storage and types of data collected</p>
              <p>
                When visiting the Website 
                <a href="https://vimpadmin.tepnglocal.iasp.tgscloud.net/" target="_blank" rel="noreferrer noopener">
                   https://vimpadmin.tepnglocal.iasp.tgscloud.net 
                </a>, 
                you may need to provide us with personal data.
              </p>


              <table className="table table-hover">
                  <thead className="thead-dark">                    
                    <tr>
                      <td> Purposes </td>
                      <td>Categories of personal data </td>
                      <td> Legal basis </td>
                      <td> Retention period </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>Vendor Invoice Processing </td>
                      <td>Identification data (last name, first name, email address) </td>
                      <td>The information collected is necessary for the implementation of the contract with TotalEnergies EP Nigeria, failing which the contract cannot be executed.</td>
                      <td>As long as the person is using the application and working for TotalEnergies EP Nigeria </td>
                    </tr>
                  </tbody>
              </table>


              <p className="bold mb-0">2. Recipients of the data</p>
              <p>In order to process your personal data according to the Charter, your personal data may be communicated to one or more of the data controller's departments or to TotalEnergies company subsidiaries and one or more partners, independent distributors or subcontractors: Certain departments at TotalEnergies based on the nature of transaction</p>



              <p className="bold mb-0">3. Data security and confidentiality</p>
              <p>The data controller takes appropriate steps to preserve the security and confidentiality of your personal data, including to prevent them from being distorted, damaged, or disclosed to unauthorized third parties.</p>



              <p className="bold mb-0">4. Cookies management </p>
              <p>
                A cookie is a file which enables a website to save information relating to your computer's browsing of the website 
                <a href="https://vimpadmin.tepnglocal.iasp.tgscloud.net/" target="_blank" rel="noreferrer noopener"> 
                   https://vimpadmin.tepnglocal.iasp.tgscloud.net 
                </a> 
                to make your visits to the website smoother. 
              </p>
              <p>
                Cookies are text files that are stored on your computer's browser. They allow access to various information about you. Some of them belong to the publisher of the Website 
                <a href="https://vimpadmin.tepnglocal.iasp.tgscloud.net/" target="_blank" rel="noreferrer noopener"> 
                   https://vimpadmin.tepnglocal.iasp.tgscloud.net 
                </a> 
                (First party) and some to third parties.
              </p>
              <p>The table below details each cookie.</p>
              <p>
                We enable cookies once you have given your consent, except for technical cookies that are necessary to provide the service you request on the Website 
                <a href="https://vimpadmin.tepnglocal.iasp.tgscloud.net/" target="_blank" rel="noreferrer noopener"> 
                   https://vimpadmin.tepnglocal.iasp.tgscloud.net 
                </a>
              </p>


              <table className="table table-hover">
                <thead className="thead-dark">
                  <tr>
                    <td>Categories of cookies and trackers</td>
                    <td>Name of the cookies and trackers </td>
                    <td>Objectives of the cookies and trackers </td>
                    <td>Editor</td>
                    <td>Retention period of the cookies and trackers </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Technical cookie</td>
                    <td>TepngUserRefreshToken </td>
                    <td>Authentication Cookie for JavaScript Web Token Refresh </td>
                    <td>HttpOnly, Secure </td>
                    <td>24 hours</td>
                  </tr>
                </tbody>
              </table>


              <p>How to withdraw your consent?</p>
              <p>
                On your first connection to the Website 
                <a href="https://vimpadmin.tepnglocal.iasp.tgscloud.net/" target="_blank" rel="noreferrer noopener"> 
                   https://vimpadmin.tepnglocal.iasp.tgscloud.net 
                </a>,  
                you will be asked to give your consent to the use of non-essentials cookies and/or to make setting. If you subsequently wish to change your choices, you can manage your cookies by going to the section at the bottom of the page of our site or application or via a reminder button called cookies.
              </p>
              <p>To delete cookies already stored, please refer to the procedure provided by your operating system (Windows, OS X, etc.).</p>




              <p className="bold mb-0">5. Your rights / Contact</p>
              <p>
                In accordance with the current regulations, you have the right to access and correct your data and to give post-mortem instructions. Regarding processing based on the performance of a contract or the implementation of pre-contractual measures, you have also the right to delete your data and to data portability and you can request a restriction of processing.
              </p>
              <p>
                To exercise your rights and question us about your personal data processing, you can contact Human Resource / Vendor Management 
                <a href="file:///C%3A/cdn-cgi/l/email-protection" target="_blank" rel="noreferrer noopener"> [email protected] </a> 
                 ep-ng.dpl@totalenergies.com If you feel, after contacting us, that your rights have not been respected, you can lodge a complaint with the competent supervisory authority. 
              </p>
            </div>
          </div>

          <div className='row mt-3 mb-5'>
            <div className="col-12 text-end">
            <button className="btn btn-small btn-primary font-14" onClick={()=>confirmPrompt("Please click yes to proceed").then( (r) => r.isConfirmed ?  this.ConsentToPersonalDataAndCookies(): false)}>
            I have read, understood and consent to the use of my name and TotalEnergies email
            </button>

            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(ConsentToPersonalDataAndCookies);
